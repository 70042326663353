import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import formatTime from './../utils/formatTime';
import validation from './../utils/validation';
import classNames from './../utils/classNames';

import { financesWithdrawalFetch } from './../store/finances/actions';

import Modal from './Modal';
import Icon from './Icon';
import Radio from './Radio';
import Tooltip from 'components/Tooltip';

const AMOUNT_TYPE_ALL = 'all';
const AMOUNT_TYPE_CUSTOM = 'custom';

class FinancesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amountType: AMOUNT_TYPE_ALL,
      amountValue: '',
      showError: false,
      verifyCode: '',
    };

    this.handleChangeCode = this.handleChangeCode.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading && !this.props.isoading && !this.props.withdrawalErrorMessage) {
      this.props.toggleModal();
      this.setState(state => Object.assign({}, state, {
        amountType: AMOUNT_TYPE_ALL,
        amountValue: '',
        showError: false,
      }));
    }
  }

  onInputChange(event) {
    const { target } = event;
    this.setState(state => Object.assign({}, state, {
      [target.name]: target.value,
    }));
  }

  onInputClick() {
    const { amountType } = this.state;
    if (amountType === AMOUNT_TYPE_CUSTOM) {
      return;
    }
    this.setState(state => Object.assign({}, state, {
      amountType: AMOUNT_TYPE_CUSTOM,
    }));
  }

  onSubmit() {
    const { verifyCode } = this.state;
    const { isLoading, submitWithdrawal } = this.props;
    if (isLoading) {
      return;
    }
    if (this.getError()) {
      this.setState(state => Object.assign({}, state, {
        showError: true,
      }));
      return;
    }
    const amount = this.getWithdrawalAmount();
    submitWithdrawal(amount, verifyCode);
  }

  getWithdrawalAmount() {
    const { available } = this.props;
    const { amountType, amountValue } = this.state;

    const error = this.getError();
    if (error) {
      return 0;
    }

    if (amountType === AMOUNT_TYPE_ALL) {
      return available;
    }

    return amountValue * 100;
  }


  getError() {
    const { amountType, amountValue } = this.state;
    const { minWithdrawal, available } = this.props;
    if (amountType === AMOUNT_TYPE_ALL) {
      return null;
    }
    if (!amountValue.length) {
      return 'Required value';
    }
    if (!validation.isCurrency(amountValue)) {
      return 'Invalid currency value';
    }
    if (!validation.isNotLess(amountValue, minWithdrawal / 100)) {
      return `Cannot be less than ${(minWithdrawal / 100).toFixed(2)}`;
    }
    if (!validation.isNotOver(amountValue, available / 100)) {
      return 'Exceedes the allowable amount';
    }
    return null;
  }

  handleChangeCode(event) {
    const { value } = event.target;
    const newValue = value.replace(/\D/g, '');
    this.setState(state => Object.assign({}, state, {
      verifyCode: newValue,
    }));
  }


  render() {
    const {
      isOpen,
      toggleModal,
      available,
      withdrawalTo,
      fee,
      feePerc,
      nextWithdrawal,
      isLoading,
      withdrawalErrorMessage,
      payMethod,
    } = this.props;

    if (!isOpen) {
      return null;
    }

    const { amountType, amountValue, showError, verifyCode } = this.state;
    const error = showError ? this.getError() : null;
    const inputClassName = classNames('input-text', { 'is-error': error });

    const withdrawalAmount = this.getWithdrawalAmount();
    const totalFee = !feePerc ? fee : Math.round(fee + ((withdrawalAmount * feePerc) / 100));
    const totalWithdrawalAmount = withdrawalAmount - totalFee;

    const isDisabledByPayoneer = payMethod === 'PayoneerEmail' && (totalWithdrawalAmount / 100) < 50;

    const hasError = false;

    return (
      <Modal onClose={toggleModal} wide>
        <h4 className="modal-info">Request withdrawal</h4>
        <div className="balance-box row vertical">
          <h2 className="col-5 title">Available balance</h2>
          <div className="col-2 balance-sum">${(available / 100).toFixed(2)}</div>
        </div>
        <div className="withdrawal-box">
          <div className="field-group row">
            <div className="col-4">Withdrawal method:</div>
            <div className="col-4">{withdrawalTo}</div>
          </div>
          <div className="field-group row border">
            <div className="col-4">Amount:</div>
            <div className="col-4 field-val">
              <Radio name="amountType" value="all" checked={amountType === AMOUNT_TYPE_ALL} onChange={this.onInputChange}>
                <b>${(available / 100).toFixed(2)}</b> <span className="radio-label-note">(all available balance)</span>
              </Radio>
              <Radio name="amountType" value="custom" checked={amountType === AMOUNT_TYPE_CUSTOM} onChange={this.onInputChange}>
                <input
                  type="text"
                  maxLength="6"
                  value={amountValue}
                  name="amountValue"
                  className={inputClassName}
                  placeholder="Enter amount"
                  onClick={this.onInputClick}
                  onChange={this.onInputChange}
                  readOnly={amountType !== AMOUNT_TYPE_CUSTOM}
                />
              </Radio>
              {!!error &&
                <div className="text-danger">{error}</div>
              }
            </div>
          </div>
          <div className="field-group row">
            <div className="col-4">Withdrawal fee:</div>
            <div className="col-4">${(totalFee / 100).toFixed(2)}</div>
          </div>
          <div className="field-group row">
            <div className="col-4">Request before:</div>
            <div className="col-4">{formatTime(nextWithdrawal, 'd')}, midnight</div>
          </div>
          <div className="field-group row">
            <div className="col-4">Payment due date:</div>
            <div className="col-4">{formatTime(moment(nextWithdrawal).add(10, 'days'), 'd')}, midnight</div>
          </div>
        </div>
        <div className="summary-box">
          <b>Summary:</b>
          <p>${(totalWithdrawalAmount / 100).toFixed(2)} will be withdrawn to your {withdrawalTo}</p>
          <p>on {formatTime(nextWithdrawal, 'd')}, midnight</p>
        </div>
        <div className="modal-request-to-pass__code-wrap">
          <h5 className="modal-request-to-pass__code-wrap__title mb15">Please enter the code <br />
            as shown in your authenticator app below
          </h5>
          <input autoFocus className="modal-request-to-pass__code-wrap__input" value={verifyCode} onChange={this.handleChangeCode} maxLength="6" />
          {hasError &&
            <p className="modal-request-to-pass__code-wrap__error">Incorrect code. Try again.</p>
          }
          {!!withdrawalErrorMessage &&
            <div className="text-danger">{withdrawalErrorMessage}</div>
          }
          {isLoading &&
            <div className="preloading-circle" />
          }
        </div>
        <div className="ta-right">
          <button type="button" className="btn btn-light btn-sm mr10" onClick={toggleModal}>Cancel</button>
          {verifyCode.length < 6 &&
            <Tooltip content={`You need to insert the code \nfrom your authenticator \napp to proceed`}>
              <button type="button" className="btn btn-bright btn-sm" onClick={this.onSubmit} disabled>Proceed</button>
            </Tooltip>
          }
          {verifyCode.length === 6 &&
          (
            <div className="tooltip tooltip-right tooltip-top">
              <button type="button" className="btn btn-bright btn-sm tooltip-trigger" onClick={this.onSubmit} disabled={isLoading || verifyCode.length < 6 || isDisabledByPayoneer}>Proceed</button>
              {isDisabledByPayoneer && (
                <div className="tooltip-content">
                  Unfortunately, you need to have at least $50 on your available balance to request withdrawal to Payoneer.
                </div>
              )}
            </div>
          )
          }
        </div>
        {isLoading &&
          <div className="preloading preloading-box"><p>Loading</p></div>
        }
        <button className="btn btn-close" type="button" onClick={toggleModal}><Icon className="svg-icon" iconName="close" /></button>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    available,
    pending,
    withdrawalTo,
    fee,
    feePerc,
    nextWithdrawal,
    minWithdrawal,
    withdrawalErrorMessage,
    isFetchingWithdrawal,
  } = state.finances;
  return {
    available,
    pending,
    withdrawalTo,
    fee,
    feePerc,
    nextWithdrawal,
    minWithdrawal,
    isLoading: isFetchingWithdrawal,
    withdrawalErrorMessage,
    payMethod: state.user?.pay_method,
  };
};

const mapDispatchToProps = dispatch => ({
  submitWithdrawal: (value, otpCode) => dispatch(financesWithdrawalFetch(value, otpCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancesModal);
