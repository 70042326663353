import className from 'classnames';

import Dropdown from 'components/Dropdown';
import Select from 'components/Select';
import Icon from 'components/Icon';

const PaymentMethodCountryList = ({
  countries, selectedCountry, onCountryChange, disabled,
  isNoValue = false, isVerifiedOrPending,
}) => (
  <div className={className('basis-100', { 'opacity-05': isVerifiedOrPending })}>
    <Dropdown
      up
      text={selectedCountry}
      closeAlways
      disabled={disabled}
      buttonClassName={className('btn btn-block', { 'no-country-value': isNoValue })}
    >
      <Select className="menu menu-selectable menu-selectable-overflow" onChange={onCountryChange}>
        {Object.values(countries).map(item =>
        (<li key={item} value={item} className={className('menu-item', { active: item === selectedCountry })}>
          {item}
          <Icon className="svg-icon" iconName="check-empty" />
        </li>))}
      </Select>
    </Dropdown>
  </div>
);

export default PaymentMethodCountryList;
