import React from 'react';

import utils from 'utils';

import IconTag from 'components/shared/IconTag';

import './styles/index.scss';

const ICONS_LIST = [
  'icon-shorter-deadline-order',
  'icon-duplicate-order',
  'icon-need-feedback-order',
  'instructions-under-review',
  'instructions-are-updated',
  'icon-draft',
  'icon-suggested',
  'icon-tips',
  'icon-recommended',
  'icon-trust',
  'icon-nt-order',
];

const MAX_VIEW_TAGS = 5;

const OrderTags = ({
  order = {}, user = null, isOpenStatus = true, callFromTable = false, isMobile,
}) => {
  if (user && utils.isStem(user)) return null;
  if (!isOpenStatus) return null;

  const { pathname = '' } = new URL(window.location.href) || {};

  const showFeedBackIcon = utils.isOrderNoFeedBack(order);
  const isNTorder = utils.isNTorder(order);
  const isOrderWithTips = utils.isOrderWithTips(order);
  const isDuplicateOrder = utils.isDuplicateOrder(order);
  const {
    custom_tags: customTags = [],
    is_recommended: isRecommended,
    tag_trust: tagTrust,
    is_suggested: isSuggested,
    is_prioritized: isPrioritized,
    tag_draft: tagDraft,
    is_pinned: isPinned,
    is_instructions_updated: isUpdated,
    is_viewed: isViewed,
    _id: orderId,
    tags: orderTags,
    number,
  } = order;

  const isNowViewOrder = pathname.includes(number) || pathname.includes(orderId);

  const optionView = (iconName) => {
    switch (iconName) {
      case 'icon-recommended':
        return isRecommended && !tagTrust;
      case 'icon-suggested':
        return isSuggested || isPrioritized;
      case 'icon-trust':
        return tagTrust;
      case 'icon-draft':
        return tagDraft;
      case 'icon-need-feedback-order':
        return showFeedBackIcon;
      case 'instructions-under-review':
        return !isNTorder && customTags.includes('instr_not_ok') && !isUpdated;
      case 'instructions-are-updated':
        return isUpdated && !isViewed && callFromTable;
      case 'icon-nt-order':
        return isNTorder && (!callFromTable || isMobile);
      case 'icon-tips':
        return isOrderWithTips;
      case 'icon-duplicate-order':
        return isDuplicateOrder;
      case 'icon-shorter-deadline-order':
        return orderTags.includes('turbo') || customTags.includes('turbo');
      default:
        return false;
    }
  };

  const renderBody = () => {
    if (isPinned && callFromTable) {
      return ['instructions-are-updated'].map(iconName => optionView(iconName) && <IconTag key={iconName} iconName={iconName} callFromTable={callFromTable} />);
    }

    const tags = ICONS_LIST.reduce((prev, next) => {
      if (optionView(next)) {
        prev.push(<IconTag key={next} iconName={next} callFromTable={callFromTable} />);
      }

      return prev;
    }, []);

    return tags.slice(0, MAX_VIEW_TAGS);
  };

  return (
    <div className="tags-list">
      {renderBody()}
    </div>
  );
};

export default React.memo(OrderTags);
