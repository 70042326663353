
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from 'hooks';

import { financesFetchIfNeeded } from 'store/finances/actions';
import Overview from 'components/profile/Overview';
import Icon from 'components/Icon';
import { ISSUE_TYPE } from 'components/WriterTicketing/constants/writerTicketing';

import DropdownOrders from './DropdownOrders';
import TicketFileList from './TicketFileList';
import DropdownTypeIssue from './DropdownTypeIssue';
import DropdownSubTypeIssue from './DropdownSubTypeIssue';
import WriterTicketingProcessingTime from './WriterTicketingProcessingTime';

import { WARNING_TEXT } from './constants/writerTicketing';
import { REPORT_SUB_TYPE } from './constants/writerTicketing';

import formatTime from 'utils/formatTime';


const WriterIssueRequestNew = (props) => {
  const {
    onChangeOrder, onChangeType, onChangeSubType, onChangeMessage, uploadFile, removeFile, subTypesIssue,
    isCanceledOrder, callFromOrderContent, isAllMyOrdersLoading, isUserCantSendIssue, isCompletedOrder, hasProgressOrder,
    changeReason, selectedReason,
  } = props;
  const {
    dataForm, allMyOrder, isConvertMessage, newTicketFiles, writerId, loading, isCompleteDayLater, orderRelateKey,
  } = props;

  const dispatch = useDispatch();
  const [orderText, setOrderText] = useState('');
  const prevIsAllMyOrdersLoading = usePrevious(isAllMyOrdersLoading);

  const fileList = newTicketFiles.files || [];

  const {
    message, stateOrderNumber, typeIssue, subTypeIssue, stateOrderTitle, isLoadingTickets, orderId, toggleReset, isSelected,
  } = dataForm;
  const isDisabledByEmptyOrders = (allMyOrder || []).length === 0;

  const issueType = ISSUE_TYPE[isCanceledOrder ? 'ORDER_CANCELED' : orderRelateKey];

  const issueTypeList = useMemo(() => Object.keys(issueType) || [], [issueType]);
  const fSubTypesIssue = useMemo(() => {
    if (isCompletedOrder) return subTypesIssue;

    return subTypesIssue.filter(subType => subType !== "Operator wasn't helpful");
  }, [subTypesIssue, isCompletedOrder]);

  const warningText = useMemo(() => {
    if (!typeIssue || !subTypeIssue) return '';
    if (WARNING_TEXT[typeIssue] && WARNING_TEXT[typeIssue][subTypeIssue]) return WARNING_TEXT[typeIssue][subTypeIssue].text;
  }, [subTypeIssue, typeIssue]);

  const finances = useSelector(state => state.finances);
  const { available, pending, nextWithdrawal } = finances;

  useEffect(() => {
    dispatch(financesFetchIfNeeded());
  }, []);

  useEffect(() => {
    if (stateOrderNumber) {
      setOrderText(`${stateOrderNumber}: ${stateOrderTitle}`);
      return;
    }

    if (!isAllMyOrdersLoading && prevIsAllMyOrdersLoading && !allMyOrder.length) {
      setOrderText('Not order-related');
      return;
    }

    setOrderText('');
  }, [allMyOrder, isAllMyOrdersLoading,
    prevIsAllMyOrdersLoading,
    stateOrderNumber,
    stateOrderTitle]);

  return (
    <>
      <div className="filter-wrap">
        <DropdownOrders loading={loading} toggleReset={toggleReset} orderText={orderText} isDisabled={isConvertMessage || orderId || callFromOrderContent || isDisabledByEmptyOrders} orderNumber={stateOrderNumber} onChangeOrder={onChangeOrder} orderList={allMyOrder} />
      </div>

      <div className="filter-wrap">
        <DropdownTypeIssue
          isDisabled={!isSelected || isUserCantSendIssue}
          hasProgressOrder={hasProgressOrder}
          typeIssue={typeIssue}
          onChangeType={onChangeType}
          issueTypeList={issueTypeList}
          changeReason={changeReason}
          selectedReason={selectedReason}
        />

        {isLoadingTickets && <div className="dropdown">Loading subtypes ....</div>}
        {fSubTypesIssue.length > 0 && !isLoadingTickets &&
          <DropdownSubTypeIssue typeIssue={typeIssue} subTypeIssue={subTypeIssue} onChangeSubType={onChangeSubType} subTypesIssue={fSubTypesIssue} isCompleteDayLater={isCompleteDayLater} />
        }
      </div>

      <div className="filter-wrap">
        <h3 className="filter-name">Give us more details</h3>
        <Overview
          onChangeFields={onChangeMessage}
          value={message}
          fieldName="public_overview"
          placeholder="Please describe what kind of issue do you have…"
          cntSymbols={1000}
          rows={3}
        />
      </div>

      <div className="filter-wrap">
        <TicketFileList
          fileList={fileList}
          uploadFile={uploadFile}
          removeFile={removeFile}
          buttonName="Attach file(s)"
          disabled={loading}
          writerId={writerId}
          isEdit
        />
      </div>

      {warningText &&
        <div className="warning_block">
          <div className="warning_block__icon-block">
            <Icon className="svg-icon" styles={{ width: 24, height: 24, fill: '#E89D00' }} iconName="warning-amber" />
          </div>
          <p className="warning_block__text">{warningText}</p>
        </div>
      }

      {(typeIssue === REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT || typeIssue === REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT) && available > 0 &&
        <div className="warning_block">
          <div className="warning_block__icon-block">
            <Icon className="svg-icon" styles={{ width: 24, height: 24, fill: '#E89D00' }} iconName="warning-amber" />
          </div>
          <div>
            <p className="warning_block__text">Note: you currently have funds on your balance. Before reporting this type of issue, please make sure you’ve created a withdrawal request for any funds you wish to receive, as you won’t be able to do so {typeIssue === REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT ? 'once your account is deleted' : 'when your account is frozen'}.</p>
            {pending > 0 &&
              <p className="warning_block__text">Pending funds won't be withdrawn, so consider that your pending balance will become available at {formatTime(nextWithdrawal, 'dt')}</p>
            }
          </div>
        </div>
      }


      {typeIssue !== REPORT_SUB_TYPE.I_WANT_TO_DELETE_MY_ACCOUNT && typeIssue !== REPORT_SUB_TYPE.I_WANT_TO_FREEZE_MY_ACCOUNT &&
        <WriterTicketingProcessingTime type={subTypeIssue} />
      }
    </>
  );
};

export default WriterIssueRequestNew;
