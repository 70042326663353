import React from 'react';

import Icon from 'components/Icon';
import classNames from 'utils/classNames';

const iconsLabels = {
  'icon-new': {
    iconTitle: 'New',
    iconTooltip: 'Recently placed order.',
  },
  'icon-hot': {
    iconTitle: 'Hot & urgent',
    iconTooltip: 'Order with challenging deadline and higher price.',
  },
  'icon-vip': {
    iconTitle: 'VIP order',
    iconTooltip: 'Prices for VIP orders are usually higher.',
    // iconTooltip: '',
  },
  'icon-recommended': {
    iconTitle: 'Recommended',
    iconTooltip: 'Order selected specifically for you based on your field of expertise, knowledge and experience, normally leads to a significant price increase.',
  },
  'icon-suggested': {
    iconTitle: 'Suggested',
    iconTooltip: 'Chosen for you based on your preferences and order availability.',
  },
  'icon-update': {
    iconTitle: 'Updated',
    iconTooltip: 'Support team has adjusted order instructions after the client provided necessary clarifications or missing files.',
  },
  'icon-subjects': {
    iconTitle: 'Your subjects',
    iconTooltip: 'Matching your preffered subjects.',
  },
  'icon-trust': {
    iconTitle: 'Client trusts you',
    iconTooltip: 'Client selected you to work on this order, normally leads to a significant price increase.',
  },
  'icon-draft': {
    iconTitle: 'Draft',
    iconTooltip: 'Order requires a draft to be submitted by a specific deadline. Get it done and get a bonus! ',
  },
  'icon-online-order': {
    iconTitle: 'OnlineOrder',
    iconTooltip: 'This order or at least a part of it should be submitted to client’s online dashboard. ',
  },
  'icon-need-feedback-order': {
    iconTitle: 'Feedback needed',
    iconTooltip: 'Order not accepted for some time. Tell us the problem if you know it, and we`ll try to fix the issue ASAP. ',
  },
  'instructions-under-review': {
    iconTitle: 'Instructions under review',
    iconTooltip: "The instructions might change as we are clarifying some details with the client right now. We'll let you know once the instructions are updated. ",
  },
  'instructions-are-updated': {
    iconTitle: 'Instructions are updated',
    iconTooltip: 'We improved the instructions. If you think the order still has issues, please report them.',
  },
  'icon-nt-order': {
    iconTitle: 'Tutoring Order',
    iconTooltip: 'This order: <br /> 💬 requires direct interaction with Client in live chat <br /> 💵🤝 may require price negotiation with Client (price might be increased if require more time to complete) <br /> 📹 may require an online meeting.',
    isHtml: true,
  },
  'icon-tips': {
    iconTitle: 'Tipping Client',
    iconTooltip: 'This order is from a client who’ve left you tips before!',
  },
  'icon-duplicate-order': {
    iconTitle: 'Hot offer',
    iconTooltip: 'This order is significantly higher in pay rate!',
  },
  'icon-shorter-deadline-order': {
    iconTitle: 'Turbo',
    iconTooltip: 'This is a high-priority task with an increased pay rate.',
  },
};

const IconTag = ({ iconName, callFromTable }) => {
  const iconInfo = iconsLabels[iconName];

  const getTooltipText = () => {
    if (!iconInfo) return null;

    const { iconTitle, iconTooltip, isHtml } = iconInfo;
    return (
      <div className="tooltip-content">
        <h4>{iconTitle}</h4>
        {
          isHtml ? (
            <span dangerouslySetInnerHTML={{ __html: iconTooltip }} />
          ) : iconTooltip
        }
      </div>
    );
  };

  return (
    <div className={classNames('tooltip tooltip__icon', { 'tooltip-right tooltip-top': callFromTable })}>
      <span className="tooltip-trigger">
        <Icon className="svg-icon" iconName={iconName} />
      </span>
      {getTooltipText()}
    </div>
  );
};

export { iconsLabels };

export default IconTag;
