import React from 'react';
import ReactSvgSprite from 'react-svg-sprite';

const SvgSprite = () => (
  <ReactSvgSprite
    symbols={[
      {
        name: 'logout',
        svg: [
          '<svg viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M8.482 11.424c.153 0 .277.124.277.277v1.021a.278.278 0 0 1-.277.278H.278A.278.278 0 0 1 0 12.722V.295C0 .142.125.018.278.018h8.204c.153 0 .277.124.277.277v1.021a.277.277 0 0 1-.277.278H1.576v9.83h6.906zm3.163-5.06a.207.207 0 0 1 0 .294L8.842 9.46a.208.208 0 0 1-.294 0l-.665-.665a.209.209 0 0 1 0-.295l1.266-1.266H3.668a.208.208 0 0 1-.208-.208V5.994c0-.114.093-.208.208-.208h5.48L7.884 4.52a.208.208 0 0 1 0-.294l.665-.666a.214.214 0 0 1 .294 0l2.803 2.804z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'settings',
        svg: [
          '<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M12.9 5.811c-.02-.182-.233-.318-.416-.318-.593 0-1.12-.348-1.34-.887a1.441 1.441 0 0 1 .362-1.6.36.36 0 0 0 .04-.488 6.402 6.402 0 0 0-1.026-1.035.36.36 0 0 0-.492.04c-.385.427-1.078.586-1.613.362A1.437 1.437 0 0 1 7.54.481a.36.36 0 0 0-.318-.379A6.455 6.455 0 0 0 5.767.1a.36.36 0 0 0-.32.37 1.44 1.44 0 0 1-.886 1.38c-.529.217-1.217.06-1.602-.364a.36.36 0 0 0-.488-.041A6.43 6.43 0 0 0 1.424 2.48a.36.36 0 0 0 .039.491c.45.408.596 1.057.362 1.615-.224.532-.776.874-1.408.874a.352.352 0 0 0-.374.318 6.48 6.48 0 0 0-.003 1.47c.02.183.24.319.425.319a1.419 1.419 0 0 1 1.331.886 1.44 1.44 0 0 1-.362 1.6.36.36 0 0 0-.039.488c.3.383.646.731 1.023 1.035a.36.36 0 0 0 .493-.039c.387-.428 1.08-.586 1.613-.363.559.234.91.799.876 1.405a.36.36 0 0 0 .317.378 6.41 6.41 0 0 0 1.456.004.36.36 0 0 0 .32-.37c-.022-.6.334-1.155.885-1.38.533-.218 1.218-.059 1.603.364a.36.36 0 0 0 .488.04 6.446 6.446 0 0 0 1.047-1.034.36.36 0 0 0-.039-.492 1.433 1.433 0 0 1-.362-1.614c.22-.525.751-.877 1.323-.877l.08.002a.36.36 0 0 0 .379-.317 6.47 6.47 0 0 0 .003-1.472zm-6.42 2.89a2.16 2.16 0 0 1-2.158-2.157A2.16 2.16 0 0 1 6.48 4.385a2.16 2.16 0 0 1 2.158 2.159A2.16 2.16 0 0 1 6.48 8.702z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'active-notification',
        svg: [
          '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g><rect rx="3" />',
          '<path d="M10 8.845c.071-.297.287-.55.566-.677.383-.176.808-.12 1.13.17l1.774 1.594v.188h-2.468A1.028 1.028 0 0 1 10 8.845m8.364-.486c.268-.241.644-.354.992-.251.499.147.792.568.755 1.072-.039.54-.531.94-1.078.94h-2.416V9.929l1.747-1.57m3.674 1.761h-1.171c.154-.242.256-.514.285-.802a2.044 2.044 0 0 0-1.227-2.109 2.07 2.07 0 0 0-2.242.358l-1.478 1.328a1.576 1.576 0 0 0-1.162-.508c-.46 0-.874.197-1.162.51l-1.48-1.33a2.071 2.071 0 0 0-2.243-.358 2.044 2.044 0 0 0-1.227 2.11c.03.287.131.56.286.801H8.049c-.58 0-1.049.466-1.049 1.04v1.56c0 .288.235.52.525.52h15.037c.29 0 .525-.232.525-.52v-1.56c0-.574-.47-1.04-1.05-1.04M8.043 21.957c0 .576.468 1.043 1.044 1.043h4.87v-8.696H8.043v7.653zM16.13 23H21c.576 0 1.043-.467 1.043-1.043v-7.653H16.13V23z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'arrow-up',
        svg: [
          '<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g transform="translate(1 1)"><rect rx="3" />',
          '<path d="M13.16 5.688L5.898 12.95l.867.867 5.78-5.78V19.5h1.226V8.032l5.926 5.926.867-.867z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'check',
        svg: [
          '<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g><path d="M6.5 12.5a6 6 0 1 1 0-12 6 6 0 0 1 0 12zm0-1a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />',
          '<path d="M5.009 6.132l-.684.729 1.648 1.547L8.92 5.642l-.684-.729-2.263 2.124z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'clip',
        svg: [
          '<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M13.865 1.873a3.801 3.801 0 0 0-5.48 0L.852 9.617a3.045 3.045 0 0 0 0 4.224 2.84 2.84 0 0 0 3.45.493.474.474 0 0 0 .318-.14l7.534-7.744a2.03 2.03 0 0 0 0-2.815 1.9 1.9 0 0 0-2.74 0L3.25 9.97a.508.508 0 0 0 0 .704.475.475 0 0 0 .685 0l6.164-6.335a.95.95 0 0 1 1.37 0 1.013 1.013 0 0 1 0 1.407l-7.192 7.392a1.9 1.9 0 0 1-2.74 0 2.03 2.03 0 0 1 0-2.815L9.07 2.577a2.85 2.85 0 0 1 4.11 0 3.045 3.045 0 0 1 0 4.225L6.331 13.84a.508.508 0 0 0 0 .704c.19.194.496.194.685 0l6.85-7.04c1.512-1.555 1.512-4.077 0-5.632" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'clock',
        svg: [
          '<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M7.162 8L5 5.94V2h1.186v3.473L8 7.2l-.838.8zM5.5 0a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'combined-shape',
        svg: [
          '<svg viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M6 4h8v5H6v3L0 6.5 6 1v3z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'comments',
        svg: [
          '<svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M2.415 1h11.313c.767 0 1.415.648 1.415 1.415v7.777c0 .767-.648 1.415-1.415 1.415h-6.73L4.71 14.123a.239.239 0 0 1-.26.06.234.234 0 0 1-.151-.219v-2.357H2.415A1.434 1.434 0 0 1 1 10.192V2.415C1 1.648 1.648 1 2.415 1" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'email-notification',
        svg: [
          '<svg viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g>',
          '<path d="M9.203 7.94a.863.863 0 0 0 .59-.224l-2.86-2.862-.199.142c-.214.158-.388.281-.521.37a3.253 3.253 0 0 1-.533.27 1.628 1.628 0 0 1-.62.138h-.012c-.192 0-.398-.046-.62-.138a3.207 3.207 0 0 1-.533-.27 13.669 13.669 0 0 1-.522-.37l-.198-.143L.311 7.716a.864.864 0 0 0 .591.224h8.3zM.57 3.05A3 3 0 0 1 0 2.56v4.356l2.524-2.523C2.019 4.04 1.368 3.593.57 3.05m8.971 0c-.768.52-1.42.968-1.958 1.344l2.522 2.522V2.56a3.09 3.09 0 0 1-.564.49" /><path d="M9.203 0h-8.3C.612 0 .39.098.233.293.078.488 0 .733 0 1.026c0 .237.104.494.31.77.207.276.427.493.66.651l1.156.804a351.047 351.047 0 0 1 1.478 1.03l.083.059.156.112c.122.088.224.16.305.214.08.055.178.116.293.184.114.067.223.118.324.152.102.034.196.05.282.05h.011a.885.885 0 0 0 .282-.05c.102-.034.21-.085.324-.152a5.68 5.68 0 0 0 .294-.184 10.923 10.923 0 0 0 .46-.326l.084-.06.571-.397 2.068-1.436c.275-.192.504-.423.688-.693.184-.271.276-.555.276-.852 0-.248-.09-.46-.268-.637A.871.871 0 0 0 9.203 0" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'open-new-window',
        svg: [
          '<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g><path d="M0 0v10h10V5.492H8.966v3.474H1.034V1.035h3.219V0z" />',
          '<path d="M6.552 0v1.035h1.682l-3.6 3.6.731.73 3.6-3.6v1.493H10V0z" /></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'plagreport',
        svg: [
          '<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g transform="translate(1 1)"><rect rx="2" />',
          '<path d="M5.688 8.712v1.502h1.53V8.712h-1.53zm1.482-3.59V3.25H5.727v1.873l.37 3.004h.693l.38-3.004z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'question',
        svg: [
          '<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g><circle cx="7" cy="7" r="7" />',
          '<path fill="#ffffff" d="M6.295 8.945v1.557h1.587V8.945H6.295zM4.667 5.61h1.486c0-.182.02-.353.06-.511.041-.158.103-.297.188-.415a.938.938 0 0 1 .323-.283.978.978 0 0 1 .47-.106c.27 0 .48.074.632.223.152.148.228.377.228.687a.828.828 0 0 1-.096.455c-.07.121-.164.233-.278.334a6.07 6.07 0 0 1-.374.303c-.135.101-.263.22-.385.36a2.089 2.089 0 0 0-.318.5 2.07 2.07 0 0 0-.167.728v.455h1.365v-.385a1.03 1.03 0 0 1 .197-.505 2.01 2.01 0 0 1 .36-.36c.134-.104.277-.208.429-.313a1.97 1.97 0 0 0 .728-.915c.084-.209.126-.475.126-.798A1.815 1.815 0 0 0 9.095 3.8a2.328 2.328 0 0 0-.773-.49c-.32-.132-.72-.198-1.198-.198-.371 0-.706.062-1.006.187-.3.125-.556.298-.769.52a2.375 2.375 0 0 0-.495.79 2.89 2.89 0 0 0-.187 1z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'saved-star',
        svg: [
          '<svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M8.5 0L5.873 5.267 0 6.112l4.25 4.099L3.247 16 8.5 13.267 13.753 16l-1.003-5.79L17 6.113l-5.873-.845z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'search',
        svg: [
          '<svg viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M7.469 12.294a4.83 4.83 0 0 1-4.825-4.825 4.83 4.83 0 0 1 4.825-4.825 4.83 4.83 0 0 1 4.825 4.825 4.83 4.83 0 0 1-4.825 4.825zm9.115 2.485l-3.524-3.525a6.716 6.716 0 0 0 1.163-3.785A6.762 6.762 0 0 0 7.47.714 6.762 6.762 0 0 0 .714 7.47a6.762 6.762 0 0 0 6.755 6.754 6.725 6.725 0 0 0 4.29-1.54l3.46 3.46 1.365-1.364z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'system-update',
        svg: [
          '<svg viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M7.843 13.631a1.369 1.369 0 0 1-2.738 0h2.738zM6.244 0a.404.404 0 0 1 .399.471c.243.085.565.201.987.358 2.66.989 3.849 2.236 3.802 6.424-.026 2.314.715 3.79 1.383 4.648.313.402.041.984-.467.984H.602a.603.603 0 0 1-.481-.967c.626-.855 1.325-2.336 1.301-4.665C1.379 3.065 2.486 1.818 4.964.829c.373-.15.662-.261.884-.344A.404.404 0 0 1 6.244 0z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'user',
        svg: [
          '<svg viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M12.974 13.242l-2.804-1.505a.814.814 0 0 1-.432-.717V9.997a6.43 6.43 0 0 0 1.1-2.236 1.22 1.22 0 0 0 .47-.962V5.563c0-.297-.113-.585-.314-.81V3.108c.019-.17.088-1.182-.656-2.017C9.692.367 8.646 0 7.226 0h-.002c-1.42 0-2.466.367-3.112 1.091-.744.835-.675 1.847-.656 2.017v1.645a1.219 1.219 0 0 0-.315.81v1.236c0 .377.173.727.471.962a6.43 6.43 0 0 0 1.1 2.236v1.023a.816.816 0 0 1-.433.717l-2.802 1.505A2.783 2.783 0 0 0 0 15.69v.582c0 .401.33.727.739.727h12.972a.733.733 0 0 0 .739-.727v-.583c0-1.02-.566-1.959-1.476-2.448" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'user2',
        svg: [
          '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M12 12.84c3.65 0 5.225.862 6.657 3.102l.663 1.08.18.277c.151.224.298.423.453.614l.21.245.338.361c1.33 1.529-.211 3.375-2.151 3.477l-.167.004H5.817C3.8 22 2.13 20.092 3.499 18.518l.339-.36.209-.245c.155-.19.302-.39.452-.614l.203-.313.64-1.043C6.776 13.703 8.35 12.84 12 12.84zm.3 2.263h-.6c-2.638.033-3.497.52-4.485 2.077l-.684 1.113c-.187.293-.369.557-.56.808l-.197.249-.184.219-.11.12.02.006.097.024.106.015.114.006h12.366c.078 0 .152-.008.22-.021l.096-.024.019-.006-.108-.12-.185-.22c-.267-.327-.507-.665-.757-1.056l-.193-.31-.49-.803c-.988-1.556-1.847-2.044-4.484-2.077zM12 2c2.773 0 5.022 2.271 5.022 5.072S14.774 12.145 12 12.145 6.977 9.874 6.977 7.072C6.977 4.27 9.227 2 12 2zm0 2.26c-1.538 0-2.785 1.26-2.785 2.812 0 1.553 1.247 2.813 2.785 2.813s2.784-1.26 2.784-2.813c0-1.553-1.247-2.812-2.784-2.812z" transform="translate(-325 -1072) translate(0 1064) translate(325 8)" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'papers-with-tab',
        svg: [
          '<svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M5.143 2l8.062.002.138.015.157.038.127.05.111.057.12.082.093.081 5.714 5.556c.04.04.077.08.11.124l.083.124.06.125.041.117.033.166.008.13v12.222c0 .57-.441 1.04-1.01 1.104l-.133.007H5.143c-.586 0-1.07-.429-1.135-.982L4 20.888V3.112C4 2.497 4.512 2 5.143 2zM12 4.222H6.286v15.556h11.428v-10h-4.571c-.541 0-.994-.366-1.113-.857l-.022-.125-.008-.13V4.223zm4.097 3.334l-1.811-1.762v1.762h1.811z" transform="translate(-128 -1072) translate(0 1064) translate(128.778 8)" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'finger',
        svg: [
          '<svg viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M1.567 10.284c-.312 0-.567-.216-.567-.483V1.117c0-.266.254-.483.567-.483h2.266c.313 0 .567.217.567.483V9.8c0 .267-.255.483-.567.483H1.567zm12.529 2.059c.405 1.105.143 2.792-.98 3.392-.366.195-.876.293-1.288.19a.929.929 0 0 1-.568-.426c-.142-.24-.127-.522-.178-.789a3.409 3.409 0 0 0-.944-1.805c-.865-.843-3.55-3.276-3.55-3.276V1.721a1 1 0 0 1 1-1h8.28C17.12.72 17.94 2.118 17.323 3.21c.736.47.988 1.464.557 2.227.736.471.988 1.464.557 2.227 1.27.813.845 2.827-.64 3.078-.089.015-4.287 0-4.287 0l.586 1.6z"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'check-fill',
        svg: [
          '<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g><circle cx="6" cy="6" r="6" />',
          '<path fill="#ffffff" d="M5.297 6.89L4.055 5.627l-.61.602 1.852 1.88L8.555 4.8l-.61-.6z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'error',
        svg: [
          '<svg viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g>',
          '<path d="M8.183 1.295L1.027 11.423A1 1 0 0 0 1.843 13h14.314a1 1 0 0 0 .817-1.577L9.817 1.295a1 1 0 0 0-1.634 0z" /><path fill="#ffffff" d="M9.783 4v2.742c0 .291-.02.577-.06.856-.039.28-.09.576-.15.89H8.466c-.061-.314-.112-.61-.151-.89-.04-.28-.06-.565-.06-.856V4h1.527zM8 10.56a1.021 1.021 0 0 1 .286-.711c.09-.088.196-.16.32-.212A.983.983 0 0 1 9 9.557a1.035 1.035 0 0 1 .707.292c.09.089.161.195.214.32a.993.993 0 0 1 .079.391.986.986 0 0 1-1 .997 1.02 1.02 0 0 1-.714-.286.945.945 0 0 1-.21-.315A1.023 1.023 0 0 1 8 10.56z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'watch',
        svg: [
          '<svg viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M2.932 5.462c-.361-.256-.528-.425-.604-.522a2.936 2.936 0 0 1-.632-1.831v-.424H7.63v.424c0 .671-.218 1.305-.632 1.831-.076.097-.242.266-.603.522a1.272 1.272 0 0 0 0 2.076c.36.256.527.425.603.522.414.526.632 1.16.632 1.831v.424h-.885c-.115-.672-.497-.98-.843-1.253-.337-.266-.656-.517-.828-1.196a.424.424 0 0 0-.822 0c-.172.68-.49.93-.828 1.196-.346.273-.728.581-.843 1.253h-.885v-.424c0-.671.219-1.305.632-1.831.076-.097.243-.266.603-.522a1.272 1.272 0 0 0 0-2.076m-.49 1.384c-.361.257-.616.482-.78.69A3.777 3.777 0 0 0 .849 9.89v.502A1.27 1.27 0 0 0 0 11.587v.99c0 .233.19.423.424.423h8.478c.234 0 .424-.19.424-.424v-.989a1.27 1.27 0 0 0-.848-1.194v-.502c0-.863-.281-1.678-.814-2.355-.163-.208-.417-.433-.779-.69a.423.423 0 0 1 0-.692c.362-.257.616-.482.78-.69a3.777 3.777 0 0 0 .813-2.355v-.502a1.27 1.27 0 0 0 .848-1.194v-.99A.424.424 0 0 0 8.902 0H.424A.424.424 0 0 0 0 .424v.989c0 .552.355 1.018.848 1.194v.502c0 .863.281 1.678.814 2.355.163.208.418.433.78.69.239.17.24.522-.001.692" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'devider',
        svg: [
          '<svg viewBox="0 0 12 19" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M10.646.748L.568 18.024l.864.504L11.509 1.252z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'check-empty',
        svg: [
          '<svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M1.703 3.416L.297 4.838l4.621 4.568 7.785-7.695L11.297.29 4.918 6.594z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-exclamation',
        svg: [
          '<svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd"><circle cx="7" cy="7" r="7" fill="#FC694F" />',
          '<path fill="#FFF" d="M6.16 9.173h1.68v1.649H6.16V9.173zm1.627-3.94l-.418 3.298h-.76l-.407-3.297V3.178h1.585v2.056z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-update',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#26A69A" d="M8.25 6.563v2.76c0 .262.143.51.367.645l2.34 1.387c.27.158.615.068.773-.195a.562.562 0 0 0-.195-.772L9.383 9.105v-2.55A.577.577 0 0 0 8.813 6a.567.567 0 0 0-.563.563zm7.5.562V3.158a.371.371 0 0 0-.637-.263L13.777 4.23A6.757 6.757 0 0 0 8.37 2.28c-3.142.285-5.73 2.813-6.075 5.955A6.75 6.75 0 0 0 15.697 9.84a.753.753 0 0 0-.75-.84.738.738 0 0 0-.734.645 5.261 5.261 0 0 1-5.288 4.605c-2.783-.037-5.13-2.385-5.175-5.175C3.705 6.15 6.082 3.75 9 3.75c1.447 0 2.76.593 3.713 1.538l-1.568 1.567a.376.376 0 0 0 .263.645h3.967c.21 0 .375-.165.375-.375z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-suggested',
        svg: [
          '<svg viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#FFA726" d="M11.07 0H2.93C1.317 0 0 1.214 0 2.722v11.432l.006.15c.11 1.33 1.679 2.121 2.943 1.455l4.05-2.134 4.052 2.134c1.31.691 2.949-.185 2.949-1.605V2.722C14 1.214 12.684 0 11.07 0zm0 2.143l.107.007c.312.046.544.288.544.572v10.933l-4.164-2.193-.133-.06c-.317-.12-.678-.1-.981.06l-4.164 2.193V2.722c0-.315.287-.58.65-.58h8.141z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-subjects',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#5D6CC1" fillRule="evenodd" d="M3.035 3.906l-.187-.044c-.53-.128-.53-.795 0-.924l5.573-1.342c.38-.09.78-.09 1.16 0l5.568 1.345c.532.129.532.793 0 .921l-2.816.679c.255.503.41 1.064.41 1.666a3.742 3.742 0 1 1-7.486 0c0-.602.158-1.163.41-1.666l-1.93-.465v1.538c.205.123.35.336.35.593a.69.69 0 0 1-.324.576l.456 1.822c.05.202-.061.41-.222.41H2.775c-.16 0-.272-.208-.222-.41l.456-1.822a.691.691 0 0 1-.325-.576c0-.257.146-.47.351-.593V3.906zM9 13.693l2.79-2.79a3.921 3.921 0 0 1 3.76 3.913v.28c0 .775-.629 1.404-1.404 1.404H3.854a1.404 1.404 0 0 1-1.404-1.404v-.28a3.921 3.921 0 0 1 3.76-3.913L9 13.693z" clipRule="evenodd" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-recommended',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#1998FE" d="M8.996 0C13.97 0 18 4.03 18 9c0 2.794-1.288 5.383-3.447 7.075C12.976 17.315 11.038 18 8.996 18c-2.038 0-3.986-.689-5.545-1.922C1.29 14.393 0 11.803 0 9c0-4.968 4.028-9 8.996-9zm0 2.145c-3.782 0-6.85 3.071-6.85 6.855 0 2.136.98 4.104 2.63 5.39 1.19.941 2.67 1.465 4.22 1.465 1.555 0 3.028-.521 4.232-1.467 1.646-1.29 2.626-3.26 2.626-5.388 0-3.785-3.069-6.855-6.858-6.855zM8.054 4.67c.405-.754 1.487-.754 1.891 0l1.092 2.034 2.476.33c.867.116 1.224 1.15.662 1.776l-.087.086-1.738 1.557.407 2.176c.153.825-.652 1.482-1.414 1.207l-.109-.045L9 12.709l-2.234 1.08c-.756.366-1.602-.237-1.538-1.045l.016-.117.404-2.175-1.735-1.557c-.65-.584-.363-1.639.455-1.839l.12-.023 2.476-.33zm.945 2.776l-.404.756c-.163.304-.462.51-.804.556l-.713.095.453.406c.243.219.372.535.354.857l-.016.138-.128.68.792-.38c.252-.123.541-.14.805-.053l.129.052.79.382-.126-.681c-.06-.322.031-.651.241-.896l.098-.099.45-.406-.71-.095c-.3-.04-.566-.203-.737-.447l-.067-.109-.407-.756z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-hot',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#EF5350" d="M12.818 3.42a13.26 13.26 0 0 0-1.8-2.138c-.263-.255-.706-.015-.63.345.142.705.292 1.635.292 2.468 0 1.545-1.012 2.797-2.557 2.797-1.155 0-2.1-.697-2.513-1.695a1.87 1.87 0 0 1-.15-.405c-.082-.315-.495-.412-.675-.135a8.628 8.628 0 0 0-.383.623A10.329 10.329 0 0 0 3 10.5c0 3.315 2.685 6 6 6s6-2.685 6-6c0-2.618-.81-5.048-2.182-7.08zM8.783 14.25c-1.335 0-2.415-1.05-2.415-2.355 0-1.215.787-2.07 2.107-2.34 1.103-.225 2.235-.698 3.023-1.44.21-.195.555-.105.615.172.172.765.262 1.56.262 2.363a3.59 3.59 0 0 1-3.592 3.6z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-new',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#67BC6C" fillRule="evenodd" d="M15.2 11.997l.11.117a.914.914 0 0 1 .252.636.938.938 0 0 1-.94.938H3.378a.938.938 0 0 1-.94-.938.915.915 0 0 1 .252-.636l.11-.117c.583-.613 1.515-1.592 1.515-4.403 0-2.277 1.596-4.099 3.748-4.546v-.61a.937.937 0 1 1 1.874 0v.61c2.152.447 3.748 2.27 3.748 4.546 0 2.81.932 3.79 1.514 4.403zm-4.326 2.628a1.875 1.875 0 1 1-3.748 0h3.748z" clipRule="evenodd" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-draft',
        svg: [
          '<svg viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#607D8B" d="M1.106 0L7.46.002l.134.015.097.023.087.028.126.056.07.04.118.085.09.083 4.495 4.604c.044.045.083.092.118.142l.066.107.074.165.027.09.032.165.007.133v10.129c0 .58-.427 1.06-.977 1.125l-.13.008H1.107C.54 17 .071 16.562.007 15.999L0 15.867V1.133C0 .507.495 0 1.106 0zm5.186 2.267h-4.08v12.466h8.575V6.87H7.4c-.524 0-.963-.372-1.077-.873L6.3 5.87l-.007-.132-.001-3.471zm2.93 2.337l-.717-.735v.735h.717z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-online-order',
        svg: [
          '<svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd"><g><g transform="translate(-436 -157) translate(438 159)">',
          '<rect width="15.714" height="14" x="-1" y="-1" stroke="#9CA9B5" strokeWidth="2" rx="1" />',
          '<rect width="10.286" height="4.286" x="1.714" y="1.714" fill="#9CA9B5" rx="2" />',
          '<rect width="3.429" height="2.571" x="1.714" y="7.714" fill="#9CA9B5" rx="1.286" />',
          '<rect width="5.143" height="2.571" x="6.857" y="7.714" fill="#9CA9B5" rx="1.286" />',
          '</g></g></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-feature',
        svg: [
          '<svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#FF6202" d="M11.898 0H5.04c-.424 0-.812.24-1.001.622L.119 8.494l-.05.119c-.264.719.264 1.508 1.052 1.508h2.302l-2.37 6.353c-.413 1.105.932 2.02 1.801 1.227l10.777-9.84.088-.09c.628-.705.14-1.867-.841-1.867h-2.107L12.9 1.627C13.272.88 12.73 0 11.898 0zm-1.813 2.25L7.958 6.525l-.052.119c-.263.719.265 1.508 1.053 1.508h1.022l-5.205 4.753L6.089 9.39l.038-.122c.175-.697-.349-1.397-1.087-1.397H2.933l2.8-5.623h4.352z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-trust',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#1E5093" d="M15.682 12.148c.112.004.228.05.315.143.839.872 1.678 1.002 2.522 1.224.257.068.409.33.339.592l-.067.234c-.292.994-1.02 3.015-2.733 4.409-.107.09-.244.137-.376.142-.08.004-.153-.01-.227-.034-.067-.024-.133-.063-.191-.108-1.861-1.515-2.56-3.771-2.8-4.643-.07-.261.083-.524.339-.592l.542-.139c.601-.157 1.202-.36 1.802-.91l.18-.175c.037-.042.082-.072.128-.097.07-.033.148-.05.227-.046zm-6.74-1.894c1.366 0 2.437.154 3.302.472.519.19.785.764.595 1.283-.19.518-.765.784-1.284.594-.62-.227-1.464-.349-2.612-.349-2.57 0-3.36.412-4.275 1.84l-.34.553c-.104.169-.186.3-.27.432-.224.346-.439.645-.677.935l-.082.098-.18.202.016.005c.054.016.115.028.18.035l.103.004h7.896c.552 0 1 .448 1 1 0 .513-.386.936-.884.994l-.116.006H3.418c-1.803 0-3.298-1.69-2.07-3.08l.302-.319.278-.328.131-.164c.06-.08.119-.163.201-.29l.607-.976c1.315-2.133 2.705-2.947 6.076-2.947zm.001-9.34c2.34 0 4.235 1.896 4.235 4.235 0 2.339-1.896 4.234-4.235 4.234-2.338 0-4.235-1.895-4.235-4.234S6.604.914 8.943.914zm0 2c-1.234 0-2.235 1.001-2.235 2.235s1 2.234 2.235 2.234c1.235 0 2.235-1 2.235-2.234s-1-2.235-2.235-2.235z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-rectangle',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" d="M9.357 0h25.49a4 4 0 0 1 3.527 2.112l4.82 9a4 4 0 0 1 0 3.776l-4.82 9A4 4 0 0 1 34.848 26H9.357a4 4 0 0 1-3.526-2.112l-4.82-9a4 4 0 0 1 0-3.776l4.82-9A4 4 0 0 1 9.357 0z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close',
        svg: [
          '<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M6.429 5L10 1.429 8.571 0 5 3.571 1.429 0 0 1.429 3.571 5 0 8.571 1.429 10 5 6.429 8.571 10 10 8.571 6.429 5z"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd" stroke="#FC694F" strokeWidth="1.3" transform="translate(1 1)">',
          '<circle cx="9" cy="9" r="9" />',
          '<path d="M6 12l6-6m0 6L6 6" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill-blue',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333Z" fill="#2383E5"/>',
          '<path d="M10.8333 5C10.8333 5.46024 10.4602 5.83333 10 5.83333C9.53976 5.83333 9.16667 5.46024 9.16667 5C9.16667 4.53976 9.53976 4.16667 10 4.16667C10.4602 4.16667 10.8333 4.53976 10.8333 5Z" fill="#2383E5"/>',
          '<path d="M9.16667 8.33333C9.16667 7.8731 9.53976 7.5 10 7.5C10.4602 7.5 10.8333 7.8731 10.8333 8.33333V15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53976 15.8333 9.16667 15.4602 9.16667 15V8.33333Z" fill="#2383E5"/>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill-red',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18.3333C14.6024 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39763 14.6024 1.66667 10 1.66667C5.39763 1.66667 1.66667 5.39763 1.66667 10C1.66667 14.6024 5.39763 18.3333 10 18.3333Z" fill="#FC694F"/>',
          '<path d="M10.8333 5C10.8333 5.46024 10.4602 5.83333 10 5.83333C9.53976 5.83333 9.16667 5.46024 9.16667 5C9.16667 4.53976 9.53976 4.16667 10 4.16667C10.4602 4.16667 10.8333 4.53976 10.8333 5Z" fill="#FC694F"/>',
          '<path d="M9.16667 8.33333C9.16667 7.8731 9.53976 7.5 10 7.5C10.4602 7.5 10.8333 7.8731 10.8333 8.33333V15C10.8333 15.4602 10.4602 15.8333 10 15.8333C9.53976 15.8333 9.16667 15.4602 9.16667 15V8.33333Z" fill="#FC694F"/>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'empty-clock',
        svg: [
          '<svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="#030303" fillRule="evenodd">',
          '<path d="M6.5 1.598A4.907 4.907 0 0 0 1.598 6.5 4.907 4.907 0 0 0 6.5 11.402c2.703 0 4.902-2.2 4.902-4.902A4.907 4.907 0 0 0 6.5 1.598m0 10.902c-3.308 0-6-2.692-6-6s2.692-6 6-6 6 2.692 6 6-2.692 6-6 6" />',
          '<path d="M7.647 9.027L5.75 7.131V3.5h1.04v3.2l1.593 1.592z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'calendar',
        svg: [
          '<svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd" transform="translate(1.5 .5)">',
          '<rect width="14.118" height="12.952" y="1.744" fill="#FFF" stroke="#BEC7CF" strokeWidth="1.5" rx="2" />',
          '<path fill="#BCC7D0" d="M3.062 10.034a.882.882 0 1 1 0 1.764.882.882 0 0 1 0-1.764zm3.997 0a.882.882 0 1 1 0 1.764.882.882 0 0 1 0-1.764zM3.062 5.622a.882.882 0 1 1 0 1.765.882.882 0 0 1 0-1.765zm3.997 0a.882.882 0 1 1 0 1.765.882.882 0 0 1 0-1.765zm3.996 0a.882.882 0 1 1 0 1.765.882.882 0 0 1 0-1.765z" />',
          '<path d="M3.088.662v1.985M11.03.662v1.985" stroke="#BEC7CF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-sad',
        svg: [
          '<svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd">',
          '<path stroke="#899095" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 11c0 5.524-4.477 10-10 10-5.522 0-10-4.476-10-10C1 5.477 5.478 1 11 1c5.523 0 10 4.477 10 10z" /><path fill="#899095" d="M8.5 9.125C8.5 10.16 7.941 11 7.25 11S6 10.16 6 9.125C6 8.09 6.559 7.25 7.25 7.25s1.25.84 1.25 1.875m7.5 0C16 10.16 15.441 11 14.75 11s-1.25-.84-1.25-1.875c0-1.035.559-1.875 1.25-1.875S16 8.09 16 9.125" /><path stroke="#899095" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.275 16A4.958 4.958 0 0 0 11 14.75c-1.26 0-2.396.482-3.275 1.25" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'hamburger',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd">',
          '<g fill="#2383E5">',
          '<g><g>',
          '<path d="M15.926 14.167c.409 0 .74.373.74.833 0 .427-.285.78-.654.828l-.086.005H4.074c-.409 0-.74-.373-.74-.833 0-.427.285-.78.654-.828l.086-.005h11.852zm0-5c.409 0 .74.373.74.833 0 .427-.285.78-.654.828l-.086.005H4.074c-.409 0-.74-.373-.74-.833 0-.427.285-.78.654-.828l.086-.005h11.852zm0-5c.409 0 .74.373.74.833 0 .427-.285.78-.654.828l-.086.005H4.074c-.409 0-.74-.373-.74-.833 0-.427.285-.78.654-.828l.086-.005h11.852z" transform="translate(-12 -84) translate(0 70) translate(12 14)" />',
          '</g></g></g></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'calendar2',
        svg: [
          '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd">',
          '<g fill="#98A7B3"><g>',
          '<path d="M16 4c.513 0 .936.386.993.883L17 5h1c1.055 0 1.918.815 1.995 1.85L20 7v11c0 1.055-.815 1.918-1.85 1.995L18 20H6c-1.055 0-1.918-.815-1.995-1.85L4 18V7c0-1.055.815-1.918 1.85-1.995L6 5h1c0-.552.448-1 1-1 .513 0 .936.386.993.883L9 5h6c0-.552.448-1 1-1zm2 8H6v6h12v-6zM7 7H6v3h12V7h-1c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L15 7H9c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.883L7 7z" transform="translate(-16 -240) translate(16 240)" />',
          '</g></g></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon_cnt_warning',
        svg: [
          '<svg viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#e5264b" fill-rule="evenodd" d="M9.483.267H3.957a.483.483 0 0 0-.459.33L.565 6.283a.483.483 0 0 0 .458.634h1.444L.061 12.192c-.124.271.202.526.435.34L8.452 6.18a.483.483 0 0 0-.302-.86H6.074l3.819-4.317a.483.483 0 0 0-.41-.737"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-time',
        svg: [
          '<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd" strokeWidth="2">',
          '<path d="M14 7c0 3.866-3.134 7-7 7s-7-3.134-7-7 3.134-7 7-7 7 3.134 7 7z" transform="translate(1 1)" />',
          '<path d="M7 3.5L7 7 8.75 8.75" transform="translate(1 1)" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'filters',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g transform="translate(-343 -84) translate(0 70) translate(343 14)">',
          '<path fill="#2383E5" d="M15.868 1.667H4.132c-.666 0-1.301.271-1.764.749l-.107.119c-.827.97-.79 2.431.107 3.359l4.551 4.7v6.144c0 .375.138.738.388 1.025.589.675 1.635.764 2.337.197l2.844-2.293.108-.095c.309-.298.485-.703.485-1.128l-.002-3.85 4.553-4.7c.45-.466.701-1.09.701-1.74 0-1.361-1.09-2.487-2.465-2.487zM4.132 3.262h11.736c.432 0 .807.388.807.893 0 .248-.095.484-.257.651L11.42 9.967v4.477l-2.843 2.294V9.967l-4.996-5.16c-.342-.354-.342-.95 0-1.304.152-.156.35-.24.55-.24z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'back',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="#2383E5"><g><g>',
          '<path d="M9.756 4.41c.3.301.323.774.07 1.1l-.07.08-3.577 3.577h9.654c.46 0 .834.373.834.833 0 .427-.322.78-.736.828l-.098.005H6.18l3.577 3.578c.3.3.323.773.07 1.1l-.07.078c-.3.3-.773.324-1.1.07l-.079-.07-5-5c-.03-.03-.056-.06-.08-.093l-.06-.093-.044-.094-.03-.087-.02-.098-.006-.05L3.333 10l.003-.063.014-.104.025-.093.036-.093.044-.08.053-.078.07-.078 5-5c.325-.326.852-.326 1.178 0z" transform="translate(-12 -84) translate(0 70) translate(12 14)" />',
          '</g></g></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-reset',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none"  xmlns="http://www.w3.org/2000/svg">',
          '<path d="M11.158 5.667L9 7.825 6.842 5.667 5.667 6.842 7.825 9l-2.158 2.158 1.175 1.175L9 10.175l2.158 2.158 1.175-1.175L10.175 9l2.158-2.158-1.175-1.175zM9 .667A8.326 8.326 0 0 0 .667 9 8.326 8.326 0 0 0 9 17.333 8.326 8.326 0 0 0 17.333 9 8.326 8.326 0 0 0 9 .667zm0 15c-3.675 0-6.667-2.992-6.667-6.667S5.325 2.333 9 2.333 15.667 5.325 15.667 9 12.675 15.667 9 15.667z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-dashboard',
        svg: [
          '<svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M14.714 0a3 3 0 0 1 2.995 2.824l.005.176v10a3 3 0 0 1-2.823 2.995l-.177.005H3a3 3 0 0 1-2.995-2.824L0 13V3A3 3 0 0 1 2.824.005L3 0h11.714zm0 2H3a1 1 0 0 0-.993.883L2 3v10a1 1 0 0 0 .883.993L3 14h11.714a1 1 0 0 0 .994-.883l.006-.117V3a1 1 0 0 0-.883-.993L14.714 2zM7.143 9.714v2.572H3.714V9.714h3.429zm6.857 0v2.572H8.857V9.714H14zm0-6V8H3.714V3.714H14z" fill="#9CA9B5" fillRule="nonzero" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-detail',
        svg: [
          '<svg viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g stroke="#9CA9B5" fill="none" fillRule="evenodd">',
          '<path fill="#FFF" d="M2 1h6.385L13 4.765V16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />',
          '<path d="M8.385 1v3.765H13M2.846 7.588h8.308m-8.308 2.824h8.308m-8.308 2.823h4.616" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-block',
        svg: [
          '<svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M5.998 0c2.367 0 4.285 1.95 4.285 4.357 0 .24-.195.436-.436.436h-.842a.436.436 0 0 1-.429-.357l-.007-.079c0-1.444-1.151-2.614-2.57-2.614-1.42 0-2.571 1.17-2.571 2.614l-.001 2.44H10c1.054 0 1.918.816 1.995 1.851l.005.15V14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V8.797c0-1.007.745-1.84 1.713-1.98v-2.46C1.714 1.951 3.633 0 5.999 0zm.065 9.412c-1.077 0-1.95.888-1.95 1.983 0 1.095.873 1.983 1.95 1.983s1.95-.888 1.95-1.983c0-1.095-.873-1.983-1.95-1.983z" fill="#9CA9B5" fillRule="evenodd" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-need-feedback-order',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g fill="none" fillRule="evenodd">',
          '<g fill="#FF3A33"><g>',
          '<path d="M15.222 0c.737 0 1.444.293 1.964.814.521.52.814 1.227.814 1.964v9.333c0 .737-.293 1.443-.814 1.964-.52.521-1.227.814-1.964.814H10.48l-3.34 2.673c-.601.48-1.424.574-2.116.24C4.33 17.47 3.889 16.77 3.889 16v-1.111H2.778c-.737 0-1.443-.293-1.964-.814C.293 13.555 0 12.848 0 12.111V2.778C0 2.04.293 1.335.814.814 1.334.293 2.04 0 2.778 0h12.444zm0 2H2.778c-.207 0-.404.082-.55.228-.146.146-.228.343-.228.55v9.333c0 .206.082.404.228.55.146.146.343.228.55.228h3.11V16l2-1.6 1.89-1.511h5.444c.207 0 .404-.082.55-.228.146-.146.228-.344.228-.55V2.778c0-.207-.082-.404-.228-.55-.146-.146-.344-.228-.55-.228z" transform="translate(-23 -23) translate(23 23)" /><g><path d="M1 0c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1s-1-.448-1-1V1c0-.552.448-1 1-1zM2 6.25c0-.552-.448-1-1-1s-1 .448-1 1v.1c0 .552.448 1 1 1s1-.448 1-1v-.1z" transform="translate(-23 -23) translate(23 23) translate(8 3.722)" />',
          '</g></g></g></g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'instructions-under-review',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" clipRule="evenodd" d="M9 0C13.9703 0 18 4.02972 18 9C18 13.9703 13.9703 18 9 18C4.02972 18 0 13.9703 0 9C0 4.02972 4.02972 0 9 0ZM9 2C5.13428 2 2 5.13428 2 9C2 12.8657 5.13428 16 9 16C12.8657 16 16 12.8657 16 9C16 5.13428 12.8657 2 9 2Z" fill="#FFA65D" /><path fillRule="evenodd" clipRule="evenodd" d="M9 12C9.553 12 10 12.448 10 13C10 13.552 9.553 14 9 14C8.447 14 8 13.552 8 13C8 12.448 8.447 12 9 12Z" fill="#FFA65D" /><path fillRule="evenodd" clipRule="evenodd" d="M9 4C9.51284 4 9.93551 4.38604 9.99327 4.88338L10 5V10C10 10.5523 9.55228 11 9 11C8.48716 11 8.06449 10.614 8.00673 10.1166L8 10V5C8 4.44772 8.44772 4 9 4Z" fill="#FFA65D" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-nt-order',
        svg: [
          '<svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<rect x="1" y="2" width="11" height="10" rx="1" stroke="#9CA9B5" fill="transparent" stroke-width="2" fill-rule="evenodd"></rect>',
          '<path d="M12 7L12 6.04595L13.8741 4.84118L17 2.83167L17 11.1683L13.8741 9.15882L12 7.95405L12 7Z" stroke="#9CA9B5" stroke-width="2" fill="transparent" fill-rule="evenodd"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'attention',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" clipRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm0-1.667a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666z" fill="#27B659" /><path d="M10.833 5a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0zM9.167 8.333a.833.833 0 0 1 1.666 0V15a.833.833 0 0 1-1.666 0V8.333z" fill="#27B659" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'attention-blue',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" clipRule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zm0-1.667a8.333 8.333 0 1 0 0-16.666 8.333 8.333 0 0 0 0 16.666z" fill="#2383E5" /><path d="M10.833 5a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0zM9.167 8.333a.833.833 0 0 1 1.666 0V15a.833.833 0 0 1-1.666 0V8.333z" fill="#2383E5" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-tips',
        svg: [
          '<svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g clipPath="url(#clip0_3712_843)">',
          '<path d="M12.2529 11.9644L14.6052 10.5413C15.3371 10.0903 16.2958 10.3182 16.7468 11.05C17.4521 12.1943 16.8742 12.9771 16.4567 13.2342L14.476 14.4585L11.8512 16.0755C9.83939 17.3149 7.07853 17.3552 5.11049 16.3696C5.11049 16.3696 3.2577 15.335 0.921999 15.6485V10.0143L2.69989 9.02925C4.68402 7.92048 7.21885 9.80492 7.21885 9.80492C8.18493 10.4413 9.00042 10.852 10.1248 11.0659C10.5428 11.1455 11.0581 11.1397 11.4416 11.3096C11.9174 11.5203 12.3022 11.8803 12.4225 12.4012C12.5069 12.7667 12.3481 13.2235 12.0332 13.4364C11.6062 13.7246 10.9648 13.6231 10.49 13.5622C9.31797 13.4119 8.16673 14.055 7.06983 13.619C7.05559 13.6132 7.04135 13.6077 7.02711 13.6019" stroke="#00AF6F" strokeWidth="1.84839" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />',
          '<path d="M11.6426 9.14442C11.8801 9.14442 12.2108 8.91912 12.2108 8.58144V8.24903C13.6882 8.07492 14.6115 7.2096 14.6115 5.95912C14.6115 4.87748 13.9573 4.21794 12.6276 3.95941L11.6304 3.75363C10.9603 3.62172 10.6543 3.36846 10.6543 2.97274C10.6543 2.52953 11.05 2.17602 11.6726 2.17602C12.2003 2.17602 12.5802 2.34486 13.0286 2.77224C13.2925 2.99912 13.5088 3.09937 13.7937 3.09937C14.1261 3.09937 14.4005 2.85666 14.4005 2.50842C14.4005 2.21295 14.2422 1.90165 13.9467 1.62201C13.5563 1.24211 12.9284 0.999404 12.2266 0.909707V0.529814C12.2266 0.192131 11.8262 0.00541687 11.5888 0.00541687C11.3514 0.00541687 11.0036 0.186855 11.0036 0.529814V0.899155C9.8711 1.00058 8.92368 1.88582 8.92368 3.10465C8.92368 4.18101 9.56739 4.86693 10.8443 5.13074L11.8362 5.34707C12.596 5.51064 12.8967 5.74279 12.8967 6.13852C12.8967 6.64504 12.4957 6.97745 11.7518 6.97745C11.2189 6.97745 10.7018 6.75584 10.2322 6.32846C9.93145 6.0752 9.74151 6.00133 9.50935 6.00133C9.13473 6.00133 8.83398 6.23349 8.83398 6.64504C8.83398 6.96162 9.00283 7.26764 9.2983 7.53673C9.73623 7.94301 10.4535 8.19475 11.0315 8.24903V8.57089C11.0315 8.90857 11.4052 9.14442 11.6426 9.14442Z" fill="#C08E30" />',
          '</g>',
          '<defs>',
          '<clipPath id="clip0_3712_843">',
          '<rect width="18" height="18" fill="white" />',
          '</clipPath></defs>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-blue-info',
        svg: [
          '<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<circle cx="4" cy="4.00003" r="4" fill="#2383E5" />',
          '<path d="M4.38647 1.72159V3.57205C4.38647 3.76831 4.37677 3.96134 4.35735 4.15113C4.33794 4.33876 4.31206 4.53826 4.27971 4.74962H3.73946C3.70711 4.53826 3.68122 4.33876 3.66181 4.15113C3.6424 3.96134 3.6327 3.76831 3.6327 3.57205V1.72159H4.38647ZM3.50977 5.95953C3.50977 5.89267 3.52163 5.82905 3.54535 5.76866C3.57123 5.70828 3.60574 5.65651 3.64887 5.61338C3.69416 5.57025 3.747 5.53574 3.80739 5.50986C3.86778 5.48398 3.93248 5.47104 4.0015 5.47104C4.06835 5.47104 4.13198 5.48398 4.19237 5.50986C4.25275 5.53574 4.30452 5.57025 4.34765 5.61338C4.39078 5.65651 4.42529 5.70828 4.45117 5.76866C4.47705 5.82905 4.48999 5.89267 4.48999 5.95953C4.48999 6.02855 4.47705 6.09325 4.45117 6.15364C4.42529 6.21187 4.39078 6.26255 4.34765 6.30569C4.30452 6.34882 4.25275 6.38225 4.19237 6.40597C4.13198 6.43185 4.06835 6.44479 4.0015 6.44479C3.93248 6.44479 3.86778 6.43185 3.80739 6.40597C3.747 6.38225 3.69416 6.34882 3.64887 6.30569C3.60574 6.26255 3.57123 6.21187 3.54535 6.15364C3.52163 6.09325 3.50977 6.02855 3.50977 5.95953Z" fill="white" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-duplicate-order',
        svg: [
          '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M4.575 4.24755C4.4725 3.87755 3.95625 3.76361 3.73125 4.08898C3.61841 4.24843 3.50948 4.40735 3.40484 4.57171C3.40082 4.57802 3.39682 4.58433 3.39281 4.59066C3.34513 4.66598 3.29835 4.7425 3.2525 4.82077C3.14466 4.99458 3.04129 5.17119 2.94253 5.35047C2.92468 5.38288 2.90697 5.41538 2.88942 5.44796C2.00021 7.09866 1.5 8.97017 1.5 10.9523C1.5 14.8461 4.85625 18 9 18C13.1437 18 16.5 14.8461 16.5 10.9523C16.5 7.87713 15.4875 5.0228 13.7725 2.63597C13.5155 2.28035 13.2441 1.93428 12.9583 1.5993C12.8277 1.44631 12.6942 1.29564 12.5577 1.14743C12.2936 0.860786 12.0183 0.583353 11.7319 0.31621C11.7289 0.313447 11.7259 0.310686 11.723 0.307926C11.6567 0.246276 11.5899 0.185175 11.5225 0.124637C11.1937 -0.174892 10.64 0.107017 10.735 0.52988C10.7607 0.649592 10.7865 0.774828 10.812 0.904443C10.8126 0.907571 10.8132 0.910702 10.8139 0.913836C10.8795 1.2488 10.9426 1.61274 10.9927 1.98602C11.0137 2.14317 11.0325 2.30197 11.0483 2.46097C11.0806 2.78699 11.1 3.11382 11.1 3.42884C11.1 5.24363 9.835 6.71425 7.90375 6.71425C6.78806 6.71425 5.82913 6.22534 5.2127 5.46217C5.20747 5.45568 5.20225 5.44918 5.19706 5.44265C5.05526 5.26434 4.93202 5.07134 4.82966 4.86633C4.82519 4.85736 4.82075 4.84836 4.81635 4.83935C4.79767 4.80106 4.77971 4.76236 4.7625 4.72327C4.66875 4.54708 4.63125 4.44136 4.575 4.24755ZM4.21675 7.31224C3.75292 8.44158 3.5 9.6686 3.5 10.9523C3.5 13.6262 5.84181 16 9 16C12.1582 16 14.5 13.6262 14.5 10.9523C14.5 8.79987 13.9204 6.76411 12.8901 4.95764C12.2938 7.0727 10.4657 8.71425 7.90375 8.71425C6.47569 8.71425 5.18245 8.18906 4.21675 7.31224Z" fill="#EB472A"></path>',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.2045 10.3544C13.1246 9.99921 12.6651 9.88381 12.3855 10.1338C12.157 10.3409 11.9069 10.5316 11.6402 10.7063C11.6296 10.7133 11.6189 10.7202 11.6082 10.7272C11.5038 10.7947 11.397 10.8598 11.288 10.9224C11.2864 10.9233 11.2849 10.9242 11.2833 10.9251C10.3982 11.433 9.3697 11.7818 8.35951 11.9802C6.60157 12.3264 5.55347 13.4226 5.55347 14.9805C5.55347 16.6537 6.99178 18 8.7697 18C11.4159 18 13.5641 15.9318 13.5534 13.3842C13.5534 12.8671 13.5232 12.3527 13.4641 11.8444C13.4628 11.8331 13.4614 11.8217 13.4601 11.8103C13.4377 11.6217 13.4113 11.434 13.381 11.2474C13.38 11.2411 13.379 11.2349 13.3779 11.2286C13.3297 10.9343 13.2718 10.6427 13.2045 10.3544ZM8.74595 13.9425C9.65615 13.7637 10.6263 13.4715 11.5482 13.0392L8.74595 13.9425ZM11.5482 13.0392C11.5517 13.154 11.5534 13.269 11.5534 13.3842H11.5534L11.5534 13.3925C11.5591 14.7565 10.3856 16 8.7697 16C7.97377 16 7.55347 15.4304 7.55347 14.9805C7.55347 14.6799 7.64183 14.5155 7.75392 14.3964C7.88687 14.2551 8.17672 14.0548 8.74494 13.9427" fill="#EB472A"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-shorter-deadline-order',
        svg: [
          '<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M4.575 4.24755C4.4725 3.87755 3.95625 3.76361 3.73125 4.08898C3.61841 4.24843 3.50948 4.40735 3.40484 4.57171C3.40082 4.57802 3.39682 4.58433 3.39281 4.59066C3.34513 4.66598 3.29835 4.7425 3.2525 4.82077C3.14466 4.99458 3.04129 5.17119 2.94253 5.35047C2.92468 5.38288 2.90697 5.41538 2.88942 5.44796C2.00021 7.09866 1.5 8.97017 1.5 10.9523C1.5 14.8461 4.85625 18 9 18C13.1437 18 16.5 14.8461 16.5 10.9523C16.5 7.87713 15.4875 5.0228 13.7725 2.63597C13.5155 2.28035 13.2441 1.93428 12.9583 1.5993C12.8277 1.44631 12.6942 1.29564 12.5577 1.14743C12.2936 0.860786 12.0183 0.583353 11.7319 0.31621C11.7289 0.313447 11.7259 0.310686 11.723 0.307926C11.6567 0.246276 11.5899 0.185175 11.5225 0.124637C11.1937 -0.174892 10.64 0.107017 10.735 0.52988C10.7607 0.649592 10.7865 0.774828 10.812 0.904443C10.8126 0.907571 10.8132 0.910702 10.8139 0.913836C10.8795 1.2488 10.9426 1.61274 10.9927 1.98602C11.0137 2.14317 11.0325 2.30197 11.0483 2.46097C11.0806 2.78699 11.1 3.11382 11.1 3.42884C11.1 5.24363 9.835 6.71425 7.90375 6.71425C6.78806 6.71425 5.82913 6.22534 5.2127 5.46217C5.20747 5.45568 5.20225 5.44918 5.19706 5.44265C5.05526 5.26434 4.93202 5.07134 4.82966 4.86633C4.82519 4.85736 4.82075 4.84836 4.81635 4.83935C4.79767 4.80106 4.77971 4.76236 4.7625 4.72327C4.66875 4.54708 4.63125 4.44136 4.575 4.24755ZM4.21675 7.31224C3.75292 8.44158 3.5 9.6686 3.5 10.9523C3.5 13.6262 5.84181 16 9 16C12.1582 16 14.5 13.6262 14.5 10.9523C14.5 8.79987 13.9204 6.76411 12.8901 4.95764C12.2938 7.0727 10.4657 8.71425 7.90375 8.71425C6.47569 8.71425 5.18245 8.18906 4.21675 7.31224Z" fill="#2A86E1"></path>',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M13.2045 10.3544C13.1246 9.99921 12.6651 9.88381 12.3855 10.1338C12.157 10.3409 11.9069 10.5316 11.6402 10.7063C11.6296 10.7133 11.6189 10.7202 11.6082 10.7272C11.5038 10.7947 11.397 10.8598 11.288 10.9224C11.2864 10.9233 11.2849 10.9242 11.2833 10.9251C10.3982 11.433 9.3697 11.7818 8.35951 11.9802C6.60157 12.3264 5.55347 13.4226 5.55347 14.9805C5.55347 16.6537 6.99178 18 8.7697 18C11.4159 18 13.5641 15.9318 13.5534 13.3842C13.5534 12.8671 13.5232 12.3527 13.4641 11.8444C13.4628 11.8331 13.4614 11.8217 13.4601 11.8103C13.4377 11.6217 13.4113 11.434 13.381 11.2474C13.38 11.2411 13.379 11.2349 13.3779 11.2286C13.3297 10.9343 13.2718 10.6427 13.2045 10.3544ZM8.74595 13.9425C9.65615 13.7637 10.6263 13.4715 11.5482 13.0392L8.74595 13.9425ZM11.5482 13.0392C11.5517 13.154 11.5534 13.269 11.5534 13.3842H11.5534L11.5534 13.3925C11.5591 14.7565 10.3856 16 8.7697 16C7.97377 16 7.55347 15.4304 7.55347 14.9805C7.55347 14.6799 7.64183 14.5155 7.75392 14.3964C7.88687 14.2551 8.17672 14.0548 8.74494 13.9427" fill="#2A86E1"></path>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'finances',
        svg: [
          '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M15.889 3c.57 0 1.04.431 1.104.987l.007.13v2.794h2.956c1.068 0 1.952.835 2.037 1.889l.007.168v10.976c0 1.074-.83 1.963-1.877 2.05l-.167.006H4.044c-1.068 0-1.952-.835-2.037-1.888L2 19.944V8.968c0-1.075.83-1.963 1.877-2.05l.167-.006L7 6.91V4.118c0-.574.429-1.046.982-1.11L8.112 3h7.777zm3.889 11.177H4.222v5.587h15.556v-5.587zm0-5.031H4.222v2.795h15.556V9.146zm-5-3.91H9.222V6.91h5.556V5.235z" transform="translate(-233 -1072) translate(0 1064) translate(233 8)" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'comments-with-strokes',
        svg: [
          '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g clip-path="url(#clip0_5615_44954)">',
          '<g clip-path="url(#clip1_5615_44954)">',
          '<path d="M2.11312 1.36664H12.012C12.6831 1.36664 13.2501 1.92824 13.2501 2.59297V9.33304C13.2501 9.99777 12.6831 10.5594 12.012 10.5594H6.12325L4.12125 12.7399C4.09285 12.77 4.05603 12.791 4.01551 12.8003C3.97498 12.8095 3.93259 12.8066 3.89375 12.7919C3.85487 12.7773 3.82141 12.7513 3.79781 12.7174C3.77421 12.6835 3.76159 12.6433 3.76162 12.6021V10.5594H2.11312C1.78609 10.5551 1.47367 10.4245 1.2424 10.1955C1.01113 9.96641 0.879305 9.65696 0.875 9.33304V2.59297C0.875 1.92824 1.442 1.36664 2.11312 1.36664Z" stroke="#9CA9B6"/>',
          '<path d="M3 4H11.12" stroke="#9CA9B6" stroke-width="0.9"/>',
          '<path d="M3 6H11.12" stroke="#9CA9B6" stroke-width="0.9"/>',
          '<path d="M3 8H9" stroke="#9CA9B6" stroke-width="0.9"/>',
          '</g>',
          '</g>',
          '<defs>',
          '<clipPath id="clip0_5615_44954">',
          '<rect width="14" height="13" fill="white" transform="translate(0 0.5)"/>',
          '</clipPath>',
          '<clipPath id="clip1_5615_44954">',
          '<rect width="14" height="13" fill="white" transform="translate(0 0.5)"/>',
          '</clipPath>',
          '</defs>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'working-status',
        svg: [
          '<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<g>',
          '<path d="M7.5 1.373A6.134 6.134 0 0 0 1.373 7.5 6.134 6.134 0 0 0 7.5 13.627 6.134 6.134 0 0 0 13.627 7.5 6.134 6.134 0 0 0 7.5 1.373M7.5 15C3.365 15 0 11.635 0 7.5S3.365 0 7.5 0 15 3.365 15 7.5 11.635 15 7.5 15" /><path d="M8.934 10.66L6.563 8.287V3.75h1.3v4l1.99 1.99z" />',
          '</g>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'icon-vip',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill="#AC48BD" fillRule="evenodd" d="M14.25 7.125a1.125 1.125 0 1 1 1.125 1.125c-.06 0-.122-.01-.18-.019L13.5 12.75h-9L2.805 8.231a1.15 1.15 0 0 1-.18.019 1.125 1.125 0 1 1 1.024-.66l1.695 1.016a.75.75 0 0 0 1.036-.272l1.91-3.342A1.12 1.12 0 0 1 9 3a1.12 1.12 0 1 1 .71 1.992l1.91 3.342a.749.749 0 0 0 1.036.272l1.697-1.017a1.103 1.103 0 0 1-.103-.464zm-10.5 6.75c0-.206.169-.375.375-.375h9.75c.206 0 .375.169.375.375v.75a.376.376 0 0 1-.375.375h-9.75a.376.376 0 0 1-.375-.375v-.75z" clipRule="evenodd" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'instructions-are-updated',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" clipRule="evenodd" d="M9 0C13.9703 0 18 4.02972 18 9C18 13.9703 13.9703 18 9 18C4.02972 18 0 13.9703 0 9C0 4.02972 4.02972 0 9 0ZM9 2C5.13428 2 2 5.13428 2 9C2 12.8657 5.13428 16 9 16C12.8657 16 16 12.8657 16 9C16 5.13428 12.8657 2 9 2Z" fill="#14B479" /><path fillRule="evenodd" clipRule="evenodd" d="M9 12C9.553 12 10 12.448 10 13C10 13.552 9.553 14 9 14C8.447 14 8 13.552 8 13C8 12.448 8.447 12 9 12Z" fill="#14B479" /><path fillRule="evenodd" clipRule="evenodd" d="M9 4C9.51284 4 9.93551 4.38604 9.99327 4.88338L10 5V10C10 10.5523 9.55228 11 9 11C8.48716 11 8.06449 10.614 8.00673 10.1166L8 10V5C8 4.44772 8.44772 4 9 4Z" fill="#14B479" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'order-is-recommended',
        svg: [
          '<svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fillRule="evenodd" clipRule="evenodd" d="M9 0C13.9703 0 18 4.02972 18 9C18 13.9703 13.9703 18 9 18C4.02972 18 0 13.9703 0 9C0 4.02972 4.02972 0 9 0ZM9 2C5.13428 2 2 5.13428 2 9C2 12.8657 5.13428 16 9 16C12.8657 16 16 12.8657 16 9C16 5.13428 12.8657 2 9 2Z" fill="#FB4E35" /><path fillRule="evenodd" clipRule="evenodd" d="M9 12C9.553 12 10 12.448 10 13C10 13.552 9.553 14 9 14C8.447 14 8 13.552 8 13C8 12.448 8.447 12 9 12Z" fill="#FB4E35" /><path fillRule="evenodd" clipRule="evenodd" d="M9 4C9.51284 4 9.93551 4.38604 9.99327 4.88338L10 5V10C10 10.5523 9.55228 11 9 11C8.48716 11 8.06449 10.614 8.00673 10.1166L8 10V5C8 4.44772 8.44772 4 9 4Z" fill="#FB4E35" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'warning-amber',
        svg: [
          '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M12 5.49375L19.53 18.5037H4.47L12 5.49375ZM2.74 17.5037C1.97 18.8337 2.93 20.5037 4.47 20.5037H19.53C21.07 20.5037 22.03 18.8337 21.26 17.5037L13.73 4.49375C12.96 3.16375 11.04 3.16375 10.27 4.49375L2.74 17.5037ZM11 10.5037V12.5037C11 13.0537 11.45 13.5037 12 13.5037C12.55 13.5037 13 13.0537 13 12.5037V10.5037C13 9.95375 12.55 9.50375 12 9.50375C11.45 9.50375 11 9.95375 11 10.5037ZM11 15.5037H13V17.5037H11V15.5037Z"/>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'reload',
        svg: [
          '<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path fill-rule="evenodd" clip-rule="evenodd" d="M9.09584 2.625C12.5098 2.625 15.2973 5.30728 15.4647 8.67886L15.613 8.52984C15.8999 8.24297 16.3527 8.22066 16.6652 8.4637L16.7401 8.52984C17.027 8.81672 17.0502 9.26934 16.8062 9.58172L16.7401 9.65663L15.2391 11.1571C14.9522 11.444 14.5002 11.4671 14.187 11.2233L14.112 11.1571L12.6111 9.65663C12.3002 9.34584 12.3002 8.84142 12.6111 8.52984C12.898 8.24297 13.3508 8.22066 13.6633 8.4637L13.7382 8.52984L13.8657 8.65734C13.6912 6.17667 11.6227 4.21875 9.09584 4.21875C6.45423 4.21875 4.31321 6.35916 4.31321 9C4.31321 11.6408 6.45423 13.7812 9.09584 13.7812C9.53584 13.7812 9.89295 14.1383 9.89295 14.5781C9.89295 15.018 9.53584 15.375 9.09584 15.375C5.57423 15.375 2.71899 12.5206 2.71899 9C2.71899 5.47941 5.57423 2.625 9.09584 2.625Z" />',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill-green',
        svg: [
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#00AF6F"/>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill-grey',
        svg: [
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#637280"/>',
          '</svg>',
        ].join(''),
      },
      {
        name: 'close-fill-orange',
        svg: [
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">',
          '<path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#FC694F"/>',
          '</svg>',
        ].join(''),
      },
    ]}
  />
);

export default SvgSprite;
