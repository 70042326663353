import React, { Component } from 'react';
import { connect } from 'react-redux';

import MajorSubjectsForm from 'components/MajorSubjectsForm';
import OtherSubjectsModal from 'components/OtherSubjectsModal';

import utils from 'utils';

import modalList from 'constants/modalList';

import classNames from 'utils/classNames';
import { isNewbie, isBeginner, isGroup } from 'utils/groups';
import storage from 'utils/localStorageHandler';
import getVerifyStatusText from 'utils/getVerifyStatusText';
import validation from 'utils/validation';

import alarm from 'img/icons/alarm.svg';

import AgreementContainer from 'components/agreement/AgreementContainer';
import InfoComponent from 'components/agreement/InfoModal/InfoComponent';
import Icon from 'components/Icon';
import SubjectsModal from 'components/profile/Modals/SubjectsModal';

import { userActionFetch, userFetchIfNeeded, userSetFieldFetch } from 'store/user/actions';
import { closeModalComponent, addModalComponent } from 'store/modals/actions';

import ProfileHead from './ProfileHead';
import CollapseItem from './CollapseItem';

import { MySubjectsForm, PaymentInfoForm,
  MySubjectGroupsForm, NotificationsForm } from './Forms';

import PublicProfile from './PublicProfile';

const bodyNoScrollClass = 'hide-scroll-profile';

class ProfileInner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canUpdate: false,
      isValidState: false,
      data: {},
      showMajorSubjectModal: false,
      isAddressReset: false,
    };

    this.onToggleWarning = this.onToggleWarning.bind(this);
    this.onToggleMajorModal = this.onToggleMajorModal.bind(this);
    this.onProfileUpdateCancel = this.onProfileUpdateCancel.bind(this);
    this.onProfileUpdate = this.onProfileUpdate.bind(this);
    this.onChangeFields = this.onChangeFields.bind(this);
    this.onSetStateModal = this.onSetStateModal.bind(this);
    this.getValidState = this.getValidState.bind(this);
    this.renderThanksModal = this.renderThanksModal.bind(this);
  }

  componentDidMount() {
    const { load, cannotClose, isFetching } = this.props;
    load();
    document.body.classList.add(bodyNoScrollClass);
    if ((cannotClose && !isFetching) || (storage.get('pref-subjects'))) {
      this.onToggleWarning();
    }
    this.initialDataProfile();
    this.onSetStateModal();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFetchingAction && !this.props.isFetchingAction) {
      this.initialDataProfile();
    }
    if (prevProps.isFetching && !this.props.isFetching) {
      if (prevState.data !== this.state.data) {
        this.setState({
          data: prevState.data,
        });
      }
    }

    if (prevState.isModalVisible && !this.state.isModalVisible && storage.get('pref-subjects')) {
      this.onToggleWarning();
    }
    if (prevProps.profile.profile_public_status === 'not_set' && this.props.profile.profile_public_status === 'waiting_approval') {
      this.props.onClose();
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(bodyNoScrollClass);
  }

  onToggleMajorModal() {
    this.setState(state => ({ ...state, showMajorSubjectModal: !state.showMajorSubjectModal }));
  }

  onChangeFields(field, value) {
    let newValue = value;
    newValue = utils.deleteNbsp(newValue);
    const { data, isAddressReset } = this.state;
    const splittedField = field.split(':') || [];

    if (newValue.length) {
      switch (field) {
        case 'public_initial':
          newValue = newValue && newValue.trim();
          newValue = newValue && validation.matchValidName(newValue);
          newValue = newValue && newValue.replace(/^./, newValue[0].toUpperCase());
          newValue = newValue && `${newValue.slice(0, 1).toUpperCase()}.`;
          break;

        case 'public_name':
          newValue = newValue && newValue.replace(/ +/g, '');
          newValue = newValue && validation.matchValidName(newValue);
          newValue = newValue && newValue.replace(/^./, newValue[0].toUpperCase());
          break;

        case 'address:city':
        case 'public_city':
          newValue = (newValue && validation.matchValidName(newValue)) || '';
          newValue = (newValue.length && newValue.replace(/ +/g, ' ')) || (field === 'address:city' ? (data.address?.city || '') : data[field]);
          // newValue = newValue && newValue.replace(/ +/g, ' ');
          newValue = newValue.split(/\s+/).map(word => word && word[0].toUpperCase() + word.substring(1)).splice(0, 4).join(' ');
          break;

        case 'available_to':
        case 'available_from':
        case 'birth_day':
        case 'address:street':
        case 'address:building_number':
          newValue = newValue || (data[field] || '');
          break;

        default:
          if (validation.isValidString(newValue)) {
            newValue = newValue && newValue.replace(/ +/g, ' ');
          } else if (splittedField.length > 1) {
            const { 0: key, 1: subKey } = splittedField;
            newValue = data[key][subKey] || '';
          } else {
            newValue = data[field] || '';
          }
          break;
      }
    }

    if (field === 'public_initial') {
      const input = document.getElementById(field);
      window.setTimeout(() => {
        input.setSelectionRange(0, 0);
      }, 0);
    }

    if (splittedField.length > 1) {
      const { 0: key, 1: subKey } = splittedField;
      let __value = { ...this.state.data[key], [subKey]: newValue };
      let isAddressResetNewValue = isAddressReset;

      if (key === 'address' && !isAddressReset) {
        __value = { [subKey]: newValue };
        isAddressResetNewValue = true;
      }

      this.setState({
        data: {
          ...this.state.data,
          [key]: __value,
          canUpdate: !!newValue.length,
        },
        isAddressReset: isAddressResetNewValue,
      }, () => this.hasChanges());

      return true;
    }

    this.setState({
      data: {
        ...this.state.data,
        [field]: newValue,
        canUpdate: !!newValue.length,
      },
    }, () => this.hasChanges());

    return true;
  }

  onProfileUpdateCancel() {
    this.initialDataProfile();
  }

  onProfileUpdate() {
    const { update, profile } = this.props;
    const { data } = this.state;
    const keys = Object.keys(data);

    let newData = {};
    keys.map((item) => {
      let dataProfile = '';
      const isObject = typeof data[item] === 'object';
      let value = '';

      if (isObject) {
        if (Object.keys(data[item]).some(it => (profile[item] || {})[it] !== (data[item] || {})[it])) {
          newData[item] = { ...data[item] };
        }
      } else {
        dataProfile = profile[item] || '';
        value = data[item];

        if (value !== dataProfile) {
          newData[item] = value;
        }
      }
    });

    if (Object.keys(newData).includes('public_name') && !Object.keys(newData).includes('public_initial')) {
      newData = { ...newData, public_initial: profile.public_initial };
    } else if (Object.keys(newData).includes('public_initial') && !Object.keys(newData).includes('public_name')) {
      newData = { ...newData, public_name: profile.public_name };
    }

    update(newData);
  }

  onToggleWarning() {
    this.setState(state => Object.assign({}, state, {
      isModalVisible: !state.isModalVisible,
    }));
  }

  onSetStateModal() {
    const {
      profile, isDe, addModalToState, deleteModalToState, isValid,
    } = this.props;
    const { showMajorSubjectModal, isModalVisible } = this.state;

    if (storage.get('show-thanks-modal')) {
      this.renderThanksModal();
    }

    const prefSubjects = storage.get('pref-subjects');
    const welcomeSubjectsModal = storage.get('welcome-subjects-modal');
    const isStem = utils.isStem(profile);

    if (profile.subjects && !profile.subjects.length && !isDe && !isStem) {
      if (!prefSubjects && !welcomeSubjectsModal) {
        const dataModal = modalList.SubjectsModal;
        const component = <SubjectsModal onClick={() => { storage.set('welcome-subjects-modal', true); this.forceUpdate(); deleteModalToState(dataModal.name); }} />;
        addModalToState({ component, ...dataModal });
      }

      if (!prefSubjects || showMajorSubjectModal) {
        const dataModalMajorSubjectsForm = modalList.MajorSubjectsForm;
        let component = <MajorSubjectsForm onClose={() => { this.setState({ showMajorSubjectModal: false }); this.onToggleWarning(); deleteModalToState(dataModalMajorSubjectsForm.name); }} />;
        addModalToState({ component, ...dataModalMajorSubjectsForm });

        const dataModalOtherSubjectsModal = modalList.OtherSubjectsModal;
        component = <OtherSubjectsModal onClose={() => { this.renderThanksModal(); this.onToggleWarning(); deleteModalToState(dataModalOtherSubjectsModal.name); }} onToggleMajorModal={() => { this.onToggleMajorModal(); deleteModalToState(dataModalOtherSubjectsModal.name); }} />;
        addModalToState({ component, ...dataModalOtherSubjectsModal });
      }

      if (prefSubjects) {
        const dataModal = modalList.OtherSubjectsModal;
        const component = <OtherSubjectsModal onClose={() => { this.renderThanksModal(); this.onToggleWarning(); deleteModalToState(dataModal.name); }} onToggleMajorModal={() => { this.onToggleMajorModal(); deleteModalToState(dataModal.name); }} />;
        addModalToState({ component, ...dataModal });
      }
    }

    if (profile.address && profile.address.country && profile.address.city && !profile.address.street && !profile.address.building_number) {
      const dataModal = modalList.AddressRequest;

      const component = <InfoComponent modalName="AddressRequest" btnText="Let’s do it!" autoText onNextClick={() => { this.onToggleWarning; deleteModalToState(dataModal.name); }} />;

      return addModalToState({ component, ...dataModal });
    }

    if (!isValid && !isModalVisible) {
      const dataModal = modalList.ProfileSetUp;
      const component = (
        <div className="modal-sm ta-center">
          <img className="modal-icon" src={alarm} alt="" />
          <h2 className="title mb30">Please fill in the required fields<br />before starting to use the platform</h2>
          <button type="button" className="btn btn-bright btn-big" onClick={() => { this.onToggleWarning(); deleteModalToState(dataModal.name); }}>Got it</button>
        </div>
      );

      addModalToState({ component, ...dataModal });
    }

    return null;
  }

  getLevel() {
    const { profile } = this.props;
    const group = profile.qc_group.toLowerCase();
    const isNewbieGroup = isNewbie(group);
    const isBeginnerGroup = isBeginner(group);
    const isSimpleGroup = isGroup(group);

    let levelName = isNewbieGroup ? 'Newbie' : isBeginnerGroup ? 'Beginner' : isSimpleGroup ? group.substring(0, 1) : group;
    const iconClassName = classNames('writer-group', {
      [`${levelName}-level`]: isSimpleGroup,
      newbie: isNewbieGroup,
      beginner: isBeginnerGroup,
    });
    levelName = levelName.charAt(0).toUpperCase() + levelName.slice(1);

    return ({ levelName, iconClassName });
  }

  getValidState() {
    const { data } = this.state;
    const { isDe } = this.props;

    let isValidState = !!data.public_name &&
      !!data.public_initial &&
      !!data.gender &&
      !!data.birth_day &&
      !!data.university &&
      !!data.profile_involvement &&
      !!data.major &&
      !!data.public_degree &&
      !!data.timezone_str &&
      !!data.available_from &&
      !!data.available_to &&
      !!data.profile_cellphoneos &&
      !!data.profile_employment &&
      !!data.profile_experience &&
      !!data.public_overview &&
      !!data.public_photo;

    if (isDe) {
      isValidState = isValidState && !!data.public_city && !!data.country;
    } else {
      isValidState = isValidState && !!data.address.country &&
        !!data.address.city &&
        !!data.address.street &&
        !!data.address.building_number;
    }

    return isValidState;
  }

  hasChanges() {
    const { profile } = this.props;
    const { data } = this.state;
    const keys = Object.keys(data);

    const isValidState = this.getValidState();

    const canUpdate = keys.some((item) => {
      const dataProfile = profile[item] || '';
      return data[item] !== dataProfile;
    });

    this.setState({
      canUpdate,
      isValidState,
    });
  }

  initialDataProfile() {
    const { profile, isDe } = this.props;
    if (profile) {
      let __value = {
        ...this.state.data,
        isAddressReset: false,
        public_name: profile.public_name || '',
        public_initial: profile.public_initial || '',
        gender: profile.gender || '',
        birth_day: profile.birth_day || '',
        university: profile.university || '',
        profile_involvement: profile.profile_involvement || '',
        major: profile.major || '',
        public_degree: profile.public_degree || '',
        timezone_str: profile.timezone_str || '',
        available_from: profile.available_from || '',
        available_to: profile.available_to || '',
        profile_cellphoneos: profile.profile_cellphoneos || '',
        profile_employment: profile.profile_employment || '',
        profile_experience: profile.profile_experience || '',
        public_overview: profile.public_overview || '',
        public_photo: profile.public_photo || '',
        discord_id: profile.discord_id || '',
        country: profile.country || '',
        public_city: profile.public_city || '',
      };

      if (!isDe) {
        __value = {
          ...__value,
          ...{
            address: {
              country: profile.address?.country || '',
              city: profile.address?.city || '',
              street: profile.address?.street || '',
              building_number: profile.address?.building_number || '',
            },
          },
        };
      }
      this.setState({
        data: __value,
        canUpdate: false,
      });
    }
  }

  renderThanksModal() {
    const { addModalToState, deleteModalToState } = this.props;
    const dataModal = modalList.ThanksModal;
    const component = (
      <div className="ta-center">
        <h2 className="title mb30 title--green">Thank you!</h2>
        <p className="modal-text fsL">You will be receiving notifications and<br />suggestions of the orders according to your<br />latest preferences. </p>
        <button type="button" className="btn btn-bright btn-big" onClick={() => { storage.remove('show-thanks-modal'); deleteModalToState(dataModal.name); }}>Got it</button>
      </div>
    );
    addModalToState({ component, ...dataModal });
  }

  renderContent() {
    const { profile, isDe, countryList } = this.props;
    const { data } = this.state;
    const dataLevel = this.getLevel();
    const isStem = utils.isStem(profile);

    return (
      <div className="order-detail-body person-info-box">
        <ProfileHead
          data={data}
          dataLevel={dataLevel}
          isDe={isDe}
          onChangeFields={this.onChangeFields}
          countryList={countryList}
          profile={profile}
        />
        {!isStem &&
          <CollapseItem title="Payment Info">
            <PaymentInfoForm countryList={countryList} />
          </CollapseItem>
        }
        <CollapseItem title="My subjects" isError={!profile.subjects || !profile.subjects.length}>
          {isDe && <MySubjectsForm isEditable={!isStem} />}
          {!isDe && <MySubjectGroupsForm isEditable={!isStem} />}

        </CollapseItem>
        <CollapseItem title="Notifications">
          <NotificationsForm isEditable={!isStem} />
        </CollapseItem>
      </div>
    );
  }

  render() {
    const { canUpdate, isValidState, isModalVisible } = this.state;
    const {
      profile, onClose, cannotClose, isValid, isFetching, isDe,
    } = this.props;
    const isLoading = !profile || profile.isFetching || profile.didInvalidate;
    const dataLevel = this.getLevel();
    const { last_veriff_session_object: veriffSession = {} } = profile;

    return (
      <div>
        {/* <div className="order-tab is-writer" style={{ display: 'none' }}> */}
        <div className="order-tab is-writer">
          <div className="order-tab-head">
            <div>
              {!isLoading &&
                <span className="person-info-rf-name"><b>Freelancer</b> {profile.name} <i>(WR ID: {profile.wr_id})</i></span>
              }
              <div className="as-grey-text mt4">{getVerifyStatusText(veriffSession.status)}</div>
            </div>

            {(!cannotClose && !canUpdate) &&
              <button className="btn btn-close" onClick={onClose}><Icon className="svg-icon" iconName="close" /></button>
            }

            {isValid && canUpdate &&
              <div className="cropper-container__button-wrap">
                <button type="button" className="btn btn-light btn-sm" onClick={this.onProfileUpdateCancel} disabled={!canUpdate}>Cancel</button>
                {!isValidState &&
                  <div className="tooltip tooltip-right">
                    <button type="button" className="btn btn-bright btn-sm btn--save tooltip-trigger" onClick={() => this.onProfileUpdate()} disabled={!isValidState || isFetching} >Update</button >
                    <div className="tooltip-content">Please fill in all the required fields to continue</div>
                  </div>
                }
                {isValidState &&
                  <button type="button" className="btn btn-bright btn-sm btn--save tooltip-trigger" onClick={() => this.onProfileUpdate()} disabled={!canUpdate || isFetching}>Update</button >
                }
              </div>
            }

            {!isValid &&
              <div className="cropper-container__button-wrap">
                <div className="tooltip tooltip-right">
                  <button type="button" className="btn btn-bright btn-sm btn--save tooltip-trigger" onClick={() => this.onProfileUpdate()} disabled={!isValidState || isFetching}>Update</button >
                  {!isValidState &&
                    <div className="tooltip-content">Please fill in all the required fields to continue</div>
                  }
                </div>
              </div>
            }
          </div>
          <div className="order-tab-container">
            {isLoading
              ? <div className="order-tab-loading"><p>Loading profile details</p></div>
              : this.renderContent()
            }
          </div>
        </div>
        {!isModalVisible &&
          <PublicProfile data={this.state.data} isLoading={isLoading} profile={profile} dataLevel={dataLevel} isDe={isDe} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.user,
  isLoading: state.user.isLoading,
  isFetchingAction: state.user.isFetchingAction,
  isFetchingFields: state.user.fetchingFields.length || false,
  isFetching: state.user.isFetchingAction || state.user.isFetchingSubjects,
  isDe: state.user && (state.user.profile_type === 'D'),
  countryList: (state.settings && state.settings.countryList) || null,
});

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(userFetchIfNeeded()),
  update: data => dispatch(userActionFetch({ action: 'update_public', ...data })),
  testAgreement: () => {
    dispatch(userSetFieldFetch('profile_tag_policies_agree', false));
    dispatch(userSetFieldFetch('profile_tag_terms_agree', false));
  },
  addModalToState: data => dispatch(addModalComponent(data)),
  deleteModalToState: name => dispatch(closeModalComponent(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInner);
