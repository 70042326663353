/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { trackValues } from 'lrtracker';

import logout from 'utils/logout';

import Modal from 'components/Modal';
import Icon from 'components/Icon';

import { userSetFieldsFetch } from 'store/user/actions';
import { closeModalComponent } from 'store/modals/actions';

import InfoModal from './InfoModal';
import AgreementBox from './AgreementBox';
import PoliciesAndConditions from './PoliciesAndConditions';

const ReviewTC = () => {
  const [showModal, toggleShowModal] = useState(true);
  const [showLogOutModal, toggleShowLogOutModal] = useState(false);
  const dispatch = useDispatch();

  if (showModal) {
    return (<InfoModal
      modalName="ReviewTermsConditions"
      btnText="Got it"
      onNextClick={() => {
        dispatch(closeModalComponent('ReviewTermsConditions'));
        toggleShowModal(false);
      }}
    />);
  }

  return (
    <AgreementBox narrow>
      <div>
        <PoliciesAndConditions />
        <div className="row vertical space-between mt20">
          <button
            onClick={() => {
                    trackValues('termsAndConditions', 'FALSE', { context: {} });
                    toggleShowLogOutModal(true);
                  }}
            type="button"
            className="btn btn-grey btn-8-4"
          >Decline</button>
          <button
            onClick={() => {
                    trackValues('termsAndConditions', 'TRUE', { context: {} });
                    dispatch(userSetFieldsFetch({ profile_tag_policies_agree: true }));
                  }}
            type="button"
            className="btn btn-bright btn-8-4"
          >Accept</button>
        </div>
        {
                  showLogOutModal && (
                  <Modal className="ta-center" small>
                    <div className="row flex-end mb8">
                      <button
                        className="btn btn-empty"
                        type="button"
                        onClick={() => {
                          trackValues('termsAndConditions', 'cancel log out', { context: {} });
                          toggleShowLogOutModal(false);
                        }}
                      >
                        <Icon className="svg-icon" iconName="close" />
                      </button>
                    </div>
                    <div className="row on-top">
                      <i className="modal-info-orange-icon mr5" />
                      <h3 className="modal-info-orange-text ta-left">You can start working on the platform only after you accept the Terms & Conditions.</h3>
                    </div>
                    <div className="fsL ta-left line-height-1-5">Otherwise, you will be logged out. You will be able to read and accept the Terms & Conditions when you log in next time.If you have any questions, please contact us at <a href="mailto:support@liv-research.com">support@liv-research.com</a>.</div>
                    <div className="row mt20">
                      <button
                        onClick={() => {
                          trackValues('termsAndConditions', 'log out', { context: {} });
                          logout();
                        }}
                        type="button"
                        className="btn btn-bright btn-8-4"
                      >Log out</button>
                    </div>
                  </Modal>
                  )
              }
      </div>
    </AgreementBox>
  );
};

export default ReviewTC;
