/* eslint-disable quotes */
export default {
  WelcomeModal:
    '<div class="info-modal-container__header">Welcome aboard!</div>' +
    '<div class="info-modal-container__text">' +
    'We are happy to greet you at Livingston Research freelance platform.' +
    ' You are just several steps away from starting working on your first order!' +
    ' Before we continue, please read and accept Freelancer Terms and Conditions and Terms of Use.' +
  '</div>',
  WellDoneModal:
  '<div class="info-modal-container__header">Well done!</div>' +
  '<div class="info-modal-container__text">' +
  'Please fill in the required fields to start finding your first order.' +
'</div>',
  WelcomeBackWriter:
    '<div class="info-modal-container__header">Welcome back!</div>' +
    '<div class="info-modal-container__text">' +
    'Recently we updated our Terms and Conditions.<br />' +
    'Before we continue, please read them and accept.' +
  '</div>',
  ViewAwesome:
    '<div class="info-modal-container__header">Awesome!</div>' +
    '<div class="info-modal-container__text">' +
    'We’re happy you are here! Now you have access to your freelancer account. ' +
    'Fill in your profile to receive access to the order feed.' +
  '</div>',
  AddressRequest:
    '<div class="info-modal-container__header">Hi there!</div>' +
    '<div class="info-modal-container__text">' +
    'Please note that you need to fill in your location in the Profile to continue working on the platform.' +
    '<div class="mt10">No worries, it will be securely stored and won’t be shared with any third parties. Profile update will take less than <strong>1 minute</strong> of your time. You can simply click the button below to access your Profile Settings.</div>' +
    '</div>' +
    '<div class="info-modal-container__text-light">' +
    'In case you have any questions or concerns, please contact us at <a mailto:freelancer.care@liv-research.com >freelancer.care@liv-research.com </a>' +
    '</div>',
  VerificationRequired:
  '<div class="info-modal-container__header">Hey there!</div>' +
    '<div class="info-modal-container__text">' +
    'Please note that you need to <strong>verify your identity</strong> in order to be able to complete more than 3 orders and successfully withdraw payments.' +
    '<div class="mt10">No worries, the procedure is very simple and takes <strong>up to 5 minutes.</strong> All you need is a smartphone with a working camera and any government-issued ID (passport, driver’s license, etc).</div>' +
    `<div class="mt10">We do not store any of your data, it's gathered and securely stored via the verification software <strong>Veriff™.</strong></div>` +
    '<div class="mt10">You can initiate the verification process at any moment by clicking the button in the Freelancer’s cabinet header, or just clicking the button below.</div>' +
    '</div>' +
    '</div>',
  VerificationWithdrawal:
    '<div class="row mb24">' +
    '<i class="info-modal-container__info-orange-icon mr12"></i>' +
    '<div class="info-modal-container__info-orange-text">Your withdrawal request cannot be processed.</div>' +
    '</div>' +
    '<div class="info-modal-container__text">' +
    'Unfortunately, we cannot proceed with your withdrawal request until you complete <strong>a required identity verification.</strong>' +
    '<div class="mt10">No worries, the procedure is very simple and takes <strong>up to 5 minutes.</strong> All you need is a smartphone with a working camera and any government-issued ID (passport, driver’s license, etc).</div>' +
    `<div class="mt10">We do not store any of your data, it's gathered and securely stored via the verification software <strong>Veriff™.</strong></div>` +
    `<div class="mt10">You can initiate the verification process at any moment by clicking the button in the Freelancer’s cabinet header, or just clicking the button below.</div>` +
    '</div>' +
    '</div>',
  VerificationWithdrawalInProgress:
    '<div class="row mb24">' +
    '<i class="info-modal-container__info-orange-icon mr12"></i>' +
    '<div class="info-modal-container__info-orange-text">Your withdrawal request cannot be processed.</div>' +
    '</div>' +
    '<div class="info-modal-container__text">' +
    'Unfortunately, we cannot proceed with your withdrawal request until you complete <strong>a required identity verification.</strong>' +
    `<div class="mt10">We do not store any of your data, it's gathered and securely stored via the verification software <strong>Veriff™.</strong></div>` +
    `<div class="mt10">Your verification is <strong>in progress,</strong> please wait for the notification once it’s completed and try again.</div>` +
    '</div>' +
    '</div>',
  NTInfoModal:
    '<div class="info-modal-container__header">Hello!</div>' +
      '<div class="info-modal-container__text">' +
      'This is a new Tutoring order. It’s a bit different from what you’re used to, as you can <strong>chat with your client directly!</strong>' +
      `<div class="mt10 row"><i class="mt2 info-modal-container__info-orange-icon mr12 no-shrink-item"></i><div class="info-modal-container__info-orange-text info-modal-container__info-orange-text--fs-14">After 10th completed Tutoring order, you won't be able to accept Tutoring orders into work until you complete the course.</div></div>` +
      '</div>' +
      '</div>',
  NTIntroductionModal:
      '<div class="info-modal-container__header">Uh-oh!</div>' +
        '<div class="info-modal-container__text">' +
        'Seems like you haven’t passed the <strong>LR Tutors: Introduction to Tutoring</strong> course yet :(' +
        '<div class="mt10">Unfortunately, you won’t be able to join chat in this order.</div>' +
        `<div class="mt10">But it’s very easy to fix. All you need to do is follow the link below and complete the course!</div>` +
        '</div>' +
        '</div>',

  BrowserNotifications:
        '<div class="info-modal-container__header">Hey there!</div>' +
          '<div class="info-modal-container__text">' +
          'Note: <strong>browser notifications</strong> of crucial platform information are available.' +
          '<div class="mt10">At the moment we will notify you strictly about new client messages in chats, upcoming live-sessions, new revision requests and required working confirmations in orders and revisions.</div>' +
          `<div class="mt10 row">Interested? Just click the button below, and allow website to send you notifications in pop-up that will appear.</div>` +
          '</div>' +
          '</div>',

  ReviewTermsConditions:
          '<div class="info-modal-container__header">Please review our updated Terms & Conditions</div>' +
          '<div class="info-modal-container__text">' +
            'We’ve made minor updates to align with financial policies. If you’ve recently set or changed your payment method, you may need to confirm the updated Terms & Conditions.' +
            '<div class="mt10">Nothing else changes for you - we just need to keep your payment-related information up to date.</div>' +
          '</div>',
};
